// ComplaintDrafter.js
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL, ERROR_MSG_500 } from '../Constants';
import Step0 from './Step0.js';
import Step1 from './Step1.js';
import Step2 from './Step2.js';
import Step4 from './Step4.js';
import Step5 from './Step5.js';
import Step6 from './Step6.js';
import ModalWithProgressBar from '../ModalWithProgressBar.js';
import Icon from '@mdi/react';
import { mdiFileDocumentEditOutline, mdiTrayArrowUp, mdiTrayArrowDown, mdiCloudUploadOutline, mdiFileEditOutline, mdiProgressDownload, mdiNewBox, mdiPencilOutline, mdiOrderBoolAscendingVariant } from '@mdi/js';
import '../global.css';
import { ensureHeaders } from '../auth/useSetHeaders';


function ComplaintDrafter() {
  const [fileName, setFileName] = useState(process.env.PUBLIC_URL + '/CV-126.pdf');
  const [key, setKey] = useState(0);
  const [appState, setAppState] = useState(0); // Initial state
  const [taskId, setTaskId] = useState(null);  // For async
  const [selectedFile, setSelectedFile] = useState(null);
  const [coas, setCoas] = useState([{'Patent Infringement': {
    'description': "Waymo alleges that Uber's use of LiDAR technology infringes on its patented systems, as they uncovered evidence showing design overlaps.", 
    'citations': [
      '35 U.S.C. § 271 (elements: unauthorized making, using, or selling of a patented invention)',
      '35 U.S.C. § 282 (establishes a presumption of validity for issued patents and shifts the burden of proof)'
    ],
    'filtered_citations': [
      '35 U.S.C. § 271 (elements: unauthorized making, using, or selling of a patented invention)',
      '35 U.S.C. § 282 (limitations on defenses in infringement cases)'
    ],
    'filtered_citations_info': [
      {
        'prefix': 'https://www.law.cornell.edu/uscode/text',
        'id': null,
        'displayName': '35 U.S.C. § 271',
        'citationName': '35/271',
        'type': 'us'
      },
      {
        'prefix': 'https://www.law.cornell.edu/uscode/text/35/282',
        'id': null,
        'displayName': '35 U.S.C. § 282',
        'citationName': '35/282',
        'type': 'us' }]}}
      ]);
  const [pfrs, setPfrs] = useState(["Court enter judgement", "Permanent injunction", "Damages"]);
  const [intro, setIntro] = useState("A proposed into will appear here after facts have been submitted.");
  const [halls, setHalls] = useState([]);
  
  // What happens next section data
  const nextStepsData = [
    {
      id: 1,
      icon: mdiPencilOutline,
      description:
        <>
          <strong>Enter facts</strong> (left side) → <strong>auto-draft complaint</strong> (right side, next page)
        </>
    },
    {
      id: 2,
      icon: mdiOrderBoolAscendingVariant,
      description:
        <>
          <strong>Select claims and relief</strong> → <strong>auto-update complaint</strong>
        </>
    },
    {
      id: 3,
      icon: mdiFileEditOutline,
      description:
        <>
          <strong>Edit Introduction and verify citations</strong> → <strong>finalize your draft</strong>
        </>
    },
    {
      id: 4,
      icon: mdiProgressDownload,
      description:
        <>
          <strong>Download your draft Complaint in Word</strong>
        </>
    },
    {
      id: 5,
      icon: mdiNewBox,
      description:
        <>
          <strong>Quick tip:</strong> Legal research is always available at the top—just ask in plain English.
        </>,
      isSeparate: true,
    },
  ];
  
  // modal 
  const [modalIsActive, setModaIsActive] = useState(false);
  const [modalProgress, setModalProgress] = useState(0);

  const ERROR_MSG = "There was an error submitting the form. Please check your inputs. If the problem persists, contact support.";

  let { draftId } = useParams();

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    console.log("file changed");
    console.log(event.target.files[0]);
  };

  const advSt = () => {
    setAppState(appState + 1);
  }

  const plaintiffRef = useRef();

  // post form to API/complaintdrafter
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    if (selectedFile) {
      formData.append('file', selectedFile);
    }

    formData.appState = appState;
    setModaIsActive(true);
    setModalProgress(5);
    formData.append('appState', appState);
    formData.append('draftId', parseInt(draftId));

    try {
      await ensureHeaders();
      const response = await axios.post(API_URL + 'complaintdrafter', formData);
      const result = response.data;
      if (response.status === 200) { // Check if the request was successful
        setTaskId(result.result_id);
        console.log('Task submitted successfully');
      } else {
        console.error('Form submission failed');
        setTaskId(null);
        setModaIsActive(false);
        alert(ERROR_MSG);
      }
    } catch (error) {
      console.error('Form submission failed', error.response ? error.response.data : error);
      setTaskId(null);
      setModaIsActive(false);
      alert(ERROR_MSG);
    }
  };

  // Function to check the task status
  const checkTaskStatus = async () => {
    try {
      console.log("checking the status");
      const response = await axios.get(`${API_URL}result/${taskId}`);
      if (response.data.ready) {
        setTaskId(null); // Reset taskId
        console.log("the data is ready")

        // this could be streamlined with a map
        if ('intro' in response.data.value){
          setIntro(response.data.value.intro);
        }
        if ('pfr_suggestions' in response.data.value){
          setPfrs(response.data.value.pfr_suggestions);
        }
        if ('coa_suggestions' in response.data.value){
          setCoas(response.data.value.coa_suggestions);
        }
        if ('halls' in response.data.value){
          setHalls(response.data.value.halls);
        }

        setModalProgress(100);
        // update the file to display
        setFileName(response.data.value.url);
        setKey(prevKey => prevKey + 1);
        advSt();

        setModaIsActive(false);
        
      }
    } catch (error) {
      console.error('Error checking task status:', error);
      setTaskId(null);
      setModaIsActive(false);
      alert(ERROR_MSG_500);
    }
  };
  
  // Start polling when taskId is set
  useEffect(() => {
    let intervalId;
    if (taskId) {
      intervalId = setInterval(() => {
        checkTaskStatus();
        let delta = appState === 0 ? 7 : 25;
        setModalProgress(modalProgress => modalProgress + delta);
      }, 5000); // Poll every 5000 milliseconds (5 seconds)
    }
    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [taskId]);

  const tabs = [
    { icon: mdiTrayArrowUp, text: 'Facts' },
    { icon: mdiFileDocumentEditOutline, text: 'Claims' },
    { icon: mdiFileDocumentEditOutline, text: 'Relief' },
    { icon: mdiFileDocumentEditOutline, text: 'Intro' },
    { icon: mdiFileDocumentEditOutline, text: 'Check' },
    { icon: mdiTrayArrowDown, text: 'Finish' }
];

  return (
    <div className="App">
        <div className="split left">
            <div className="dropdown-container" style={{margin: '20px 32px'}}>
                <div className="tabs is-toggle is-progress">
                  <ul>
                      {tabs.map((tab, index) => (
                          <li key={index} className={index === appState ? 'is-active' : ''}>
                          <a onClick={() => setAppState(index)} style={{ padding: '8px', display: 'inline-flex', alignItems:'center' }}>
                              <span className="icon is-small">
                                <Icon path={tab.icon} size={1} />
                              </span>
                              <span>{tab.text}</span>
                          </a>
                          </li>
                      ))}
                  </ul>
              </div>
                <div>

                    {/* Conditional rendering based on appState */}
                    {appState === 0 && <Step0 onSubmit={handleSubmit} advSt={advSt} onChange={handleFileChange} fileName={selectedFile}/>}
                    {appState === 1 && <Step1 onSubmit={handleSubmit} advSt={advSt} initialCoas={coas}/>}
                    {appState === 2 && <Step2 onSubmit={handleSubmit} advSt={advSt} initialPfrs={pfrs}/>}
                    {appState === 3 && <Step4 onSubmit={handleSubmit} advSt={advSt} intro={intro}/>}
                    {appState === 4 && <Step5 onSubmit={handleSubmit} advSt={advSt} halls={halls}/>}
                    {appState === 5 && <Step6 draftId={draftId} />}
                </div>
            </div> 
            <ModalWithProgressBar isActive={modalIsActive} progress={modalProgress} />
        </div>
        <div className="split right">
          {appState === 0 ? (
            <>
              {/* Instructions */}
              <div style={{ marginTop: "20px", marginRight: "20px", paddingBottom: "24px", display: 'flex', height: "calc(100% - 20px)" }}>
                <div style={{ background: "#F8FAFC", border: '1px solid #D1D1D6', borderRadius: '24px', flex: 1, padding: '0px 32px' }}>
                  <div className='hide-scrollbar' style={{ maxWidth: "402px", margin: "0 auto", paddingTop: "110px", height: "100%", overflowY: "auto", paddingBottom: '40px',  }}>
                    <div style={{ paddingBottom: '40px', textAlign: 'center' }}>
                      <h2 className='title is-4' style={{ color: "var(--color-primary)" }}>What Happens Next</h2>
                    </div>

                    {/* What happens next bullets */}
                    <div style={{ padding: '0px 12px 28px 12px', borderBottom: '1px solid #C7C7CC', display: 'flex', flexDirection: 'column', gap: '24px' }}>
                      {nextStepsData
                        .filter((step) => !step.isSeparate)
                        .map((step) => (
                          <div key={step.id} style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                            <span style={{ height: '32px' }}>
                              <Icon path={step.icon} size="32px" title={step.title} color={ "var(--color-primary)" }/>
                            </span>
                            <p> {step.description} </p>
                          </div>
                        ))}
                    </div>

                    {/*Legal research now available*/}
                    <div style={{ padding: '28px 12px 0px 12px' }}>
                      {nextStepsData
                        .filter((step) => step.isSeparate)
                        .map((step) => (
                          <div key={step.id} style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                            <span style={{ height: '32px' }}>
                              <Icon path={step.icon} size="32px" title={step.title} color={ "var(--color-primary)" }/>
                            </span>
                            <p> {step.description}</p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ):(
            <>
              {/* pdf viewer */}
              <embed key={key} src={fileName} width={"100%"} height={2000} />
            </>
          )}
        </div>
    </div>
  );
}

export default ComplaintDrafter;
