import React from 'react';

function Step2({ coaDict = [], onSubmit }) { // Ensure coaDict is always an array
    console.log("Step2 received coaDict:", JSON.stringify(coaDict, null, 2));
  return (
    <>
      <div className="container">
        <h1 className="title is-4">Legal Grounds for Dismissal</h1>
        <form onSubmit={onSubmit}>
        
        {coaDict.length > 0 ? (
          coaDict.map((coa, coaIndex) => (
            <div key={coaIndex} className="box" style={{ marginBottom: '2rem' }}>
              {/* Display the Name of the Cause of Action at the Top */}
              <h2 className="title is-5">{coa.title || "No Name Provided"}</h2>
              
              {/* Description */}
              <p className="subtitle is-6">{coa.description || "No description available"}</p>

              {/* Elements Section */}
              {/* <div className="content">
                <h3 className="title is-6">Elements Analysis</h3>
                {coa.elements?.length > 0 ? (
                  coa.elements.map((element, elementIndex) => (
                    <div key={elementIndex} className="box" style={{ marginLeft: '1rem' }}>
                      <p><strong>Element:</strong> {element.element || "No element specified"}</p>
                      <p><strong>Matched Allegation:</strong> {element['matched allegation'] || "No matched allegation"}</p>
                      <p><strong>Potential Deficiency:</strong> {element['potential deficiency'] || "No deficiency listed"}</p>
                    </div>
                  ))
                ) : (
                  <p>No elements available</p>
                )}
              </div> */}

              {/* Legal Grounds Section */}
              <div className="content">
                <h3 className="title is-6">Legal Grounds</h3>
                {coa.grounds?.length > 0 ? (
                  coa.grounds.map((ground, groundIndex) => (
                    <div key={groundIndex} className="box" style={{ marginLeft: '1rem' }}>
                      {/* Add debug log here */}
                      {ground.citations_info && console.log("Citations info for ground:", ground['legal ground'], ground.citations_info)}
                      
                      <p><strong>Legal Ground:</strong> {ground['legal ground'] || "No legal ground specified"}</p>
                      <p><strong>Description:</strong> {ground.description || "No description available"}</p>

                      <div className="content">
                        <p><strong>Elements:</strong></p>
                        <ul>
                          {ground.elements?.length > 0 ? (
                            ground.elements.map((el, elIndex) => (
                              <li key={elIndex}>
                                {typeof el === 'string' ? el : el.element}
                              </li>
                            ))
                          ) : (
                            <li>No elements listed</li>
                          )}
                        </ul>
                      </div>

                      <div className="content">
                    <h4 className="title is-6">Supporting Citations</h4>
                        <textarea className="textarea" 
                        name={`citations-${coaIndex}-${groundIndex}`} 
                        defaultValue={ground.citations}
                        rows={4} style={{ marginBottom: '0.5rem' }} 
                        />
                        {/* If you'd like to show citations: */}
                        {ground.citations_info && ground.citations_info.length > 0 && (
                            <ul>
                              {ground.citations_info.map((citation, cIndex) => (
                                <li key={cIndex}>
                                <a 
                                  href={citation.type === 'state' 
                                    ? citation.prefix 
                                    : `${citation.prefix}/${citation.citationName}${citation.id ? `&${citation.id}` : ''}`} 
                                  target="_blank" 
                                  rel="noopener noreferrer"
                                >
                                  {citation.displayName}
                                </a>
                              </li>
                              ))}
                            </ul>
                          )}
                      </div>
                    {/* Ignore this legal ground? */}
                    <div className="field" style={{ marginTop: '0.5rem' }}>
                    <label className="checkbox" htmlFor={`ignore-${coaIndex}-${groundIndex}`}>
                        <input
                        className="is-checkradio"
                        type="checkbox"
                        id={`ignore-${coaIndex}-${groundIndex}`}
                        name={`ignore-${coaIndex}-${groundIndex}`}
                        />
                        &nbsp; Ignore this legal ground coa{coaIndex}-{groundIndex}
                    </label>
                    </div>
                </div>
              ))
            ) : (
                  <p>No legal grounds available</p>
                )}
              </div>
            </div>
          ))
        ) : (
          <p>No data available</p>
        )}
        
        <div className="has-text-right" style={{ marginTop: '16px' }}>
          <button className="button is-normal is-success" type="submit">
            Save and Continue
          </button>
        </div>
        </form>
      </div>
    </>
  );
}

export default Step2;
