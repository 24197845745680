import React, { useState, useEffect } from 'react';

/**
 * Step4 component
 *
 * Props:
 *  - batchArguments: array of objects, each {heading: argument }
 *  - fetchNextBatch(formData): parent's function that executes the POST
 *  - draftId: the doc's ID, needed for the backend
 */
function Step3({onSubmit, batchArguments, batchCitations, setBatchCitations, fetchNextBatch, draftId }) {
  const [currentBatchIndex, setCurrentBatchIndex] = useState(0);
  const [heading, setHeading] = useState("");
  const [argument, setArgument] = useState("");
//   const [batchCitations, setBatchCitations] = useState([
//     {'prefix': '/CaseViewer', 'citationName': '...', 'id': 0, 'displayName': '...', 'type': 'case'}
// ])
  const [allDone, setAllDone] = useState(false);

  useEffect(() => {
    console.log("Updated currentBatchIndex:", currentBatchIndex);
    console.log("Updated batchArguments:", batchArguments);
    console.log("update batch citations: ", batchCitations)
  }, [batchArguments, currentBatchIndex, batchCitations]);

    // Load the heading/argument for the current batch whenever currentBatchIndex changes
    useEffect(() => {
        console.log("currentBatchIndex in useEffect:", currentBatchIndex);
        // If there is already data for this batch, load it.
        if (batchArguments.length > currentBatchIndex) {
            // Get the first (and only) entry from the object
            const [key, value] = Object.entries(batchArguments[currentBatchIndex])[0] || ["", ""];
            setHeading(key);
            setArgument(value);
          } else {
            // If no object exists for this batch yet, set empty defaults.
            setHeading("");
            setArgument("");
          }
        }, [currentBatchIndex, batchArguments]);

    // This function is triggered when the user clicks "Next Section".
  // It builds the FormData (which includes heading and argument) and calls fetchNextBatch.
  // Then, if the backend call succeeds (i.e. the new batch is generated and appended),
  // it increments currentBatchIndex so that the new batch data is loaded.
  const handleNextSection = async (e) => {
    e.preventDefault();
    console.log("Step3 handleNextSection is running!");

    // Build form data from the form.
    const formData = new FormData(e.target);
    // Append additional fields:
    const newBatchIndex = currentBatchIndex + 1; // 
    console.log("New Batch Index:", newBatchIndex); // Log the new batch index
    setCurrentBatchIndex(newBatchIndex); // Update the state
    formData.append("batchIndex", newBatchIndex);
    formData.append("appState", 2); // backend expects state2 processing
    formData.append("draftId", draftId);

    // Call fetchNextBatch and wait for it to finish.
    const response = await fetchNextBatch(formData);
    console.log("fetchNextBatch returned:", response);
    if (response.success) {
        if (response.data.value.batch_heading) {
            setHeading(response.data.value.batch_heading);
          }
          if (response.data.value.batch_argument) {
            setArgument(response.data.value.batch_argument);
          }
          if (response.data.value.batch_citations) {
            setBatchCitations(response.data.value.batch_citations)
            console.log("batch_citations: ", response.data.value.batch_citations)
          }
      console.log("all_batches_done:", response.data.value.all_batches_done);
      if (!response.data.value.all_batches_done) {
        setCurrentBatchIndex(newBatchIndex);

      }else{
        setAllDone(true);
      }
    } else {
      alert("Failed to save this batch. Please try again.");
    }
  };

  // If the user has finished all batches, show a read-only summary
  if (allDone) {
    return (
        <form onSubmit={onSubmit}>
        <div className="container">
        <h1 className="title is-4">All Batches</h1>
        {batchArguments.map((b, i) => {
        // There's 1 key in each object
        const [theHeading, theArgument] = Object.entries(b)[0];
        return (
            <div key={i} className="box">
            <h2 className="title is-5">
                Batch {i + 1}:<br /> {theHeading}
            </h2>
            <p>{theArgument}</p>
            </div>
        );
        })}
        </div>
        <div className="has-text-right" style={{ marginTop: '16px' }}>
          <button className="button is-normal is-success" type="submit">
            Save and Continue
          </button>
        </div>
      </form>
    );
  }

  return (
    <div className="container">
      <h1 className="title is-4">Draft Section Arguments</h1>
      <form onSubmit={handleNextSection}>
        <div className="field">
          <label className="label">
            Heading
          </label>
          <div className="control">
            <input
              className="input"
              type="text"
              name={`heading_${currentBatchIndex}`}
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
            />
          </div>
        </div>

        <div className="field" style={{ marginTop: "1rem" }}>
          <label className="label">
            Argument
          </label>
          <div className="control">
            <textarea
              className="textarea"
              rows={20}
              name={`argument_${currentBatchIndex}`}
              value={argument}
              onChange={(e) => setArgument(e.target.value)}
            />
          </div>
        </div>
         
        {batchCitations.length > 0 && (
            <ul>
            {batchCitations.map((citation, cIndex) => (
                <li key={cIndex}>
                <a 
                href={citation.type === 'state' 
                ? citation.prefix 
                : `${citation.prefix}/${citation.citationName}${citation.id ? `&${citation.id}` : ''}`} 
                target="_blank" 
                rel="noopener noreferrer"
            >
                {citation.displayName}
            </a>
                </li>
            ))}
            </ul>
        )}

        <div style={{ marginTop: "1.5rem" }}>
          {/* Show "Previous Section" unless we're on the first batch */}
          {currentBatchIndex > 0 && (
            <button
              type="button"
              className="button is-light"
              style={{ marginRight: "1rem" }}
              onClick={() => setCurrentBatchIndex(i => i - 1)}
            >
              Previous Section
            </button>
          )}

          <button type="submit" className="button is-info">
            Next Section
          </button>
        </div>
      </form>
    </div>
  );
}

export default Step3;
