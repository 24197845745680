// MotionDismiss.js

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL, ERROR_MSG_500 } from '../Constants.js';
import Step0 from '../answer/Step0.js';
import Step1 from './Step1.js';
import Step2 from './Step2.js';
import Step3 from './Step3.js';
import Step4 from './Step4.js';
import Step5 from './Step5.js';

import ModalWithProgressBar from '../ModalWithProgressBar.js';
import { ensureHeaders } from '../auth/useSetHeaders';
import Icon from '@mdi/react';
import { mdiFileDocumentEditOutline, mdiTrayArrowUp, mdiTrayArrowDown } from '@mdi/js';
import '../global.css';


function MotionDismiss() {
    const [fileName, setFileName] = useState(process.env.PUBLIC_URL + '/CV-126.pdf');
    const [key, setKey] = useState(0);
    const [appState, setAppState] = useState(0); // Initial state
    const [taskId, setTaskId] = useState(null);  // For async
    const [selectedFile, setSelectedFile] = useState(null);

    const [fas, setFas] = useState(['First FA', 'Second FA', 'Third FA']);
    const [background, setBackground] = useState('background paragraphs');

    const [standardCitations, setStandardCitations] = useState('citation 1 citation 2');
    const [standard, setStandard] = useState('standard paragrahs');

    const [coaDict, setCoaDict] = useState([
        {'name':'coa1', 'description':'ZYX alleged ABC..',
            'elements':[
                {'element':'....','matched allegation':'.......','potential deficiency':'.......'}], 
            'grounds':[
                {'legal ground': '.....',
                    'description':'........', 
                    'elements':['...', '...'], 
                    'citations':['...','....'], 
                    'citations_info':[{'prefix':'/CaseViewer','citationName':'...', 'id':856063, 'displayName':'.....', 'type':'...'}]
                }]
            }
        ])
    
    const [structure, setStructure] = useState("Hybrid Approach");
    const [justification, setJustification] = useState("Certain claims share common legal grounds, while others require individual analysis.")
    const [batches, setBatches] = useState(
        [{
            "batch heading": "Plaintiff's Copyright Claims Are Legally Deficient and Must Be Dismissed",
            "batch": [
            {"cause of action": "Copyright Infringement", "legal ground": "Failure to State a Claim Under Rule 12(b)(6)"},
            {"cause of action": "Contributory Copyright Infringement", "legal ground": "Failure to Allege Direct Infringement by a Third Party"}
            ],
            "citations":'....',
            "citations_info":[
                {'prefix': '/CaseViewer', 'citationName': '488-F<dot>3d-1102', 'id': 797970, 'displayName': 'Perfect 10, Inc. v. CCBill LLC\t488 F.3d 1102', 'type': 'case'}
            ]
        },
        {
            "batch heading": "Plaintiff Lacks Standing to Bring These Claims",
            "batch": [
            {"cause of action": "Copyright Infringement", "legal ground": "Lack of Standing"},
            {"cause of action": "Contributory Copyright Infringement", "legal ground": "Lack of Standing"}
            ],
            "citations":'....',
            "citations_info":[
                {'prefix': '/CaseViewer', 'citationName': '488-F<dot>3d-1102', 'id': 797970, 'displayName': 'Perfect 10, Inc. v. CCBill LLC\t488 F.3d 1102', 'type': 'case'}
            ]
        }
    ]);
    const [batchArguments, setBatchArguments] = useState([
        {'Plaintiff failed to do A...': ' ... paragraphs ....'}]);
    
    const [batchCitations, setBatchCitations] = useState([
        {'prefix': '/CaseViewer', 'citationName': '...', 'id': 0, 'displayName': '...', 'type': 'case'}
    ]);
    
    const [introduction, setIntroduction] = useState('paragraphs');
    const [conclusion, setConclusion] = useState('paragraph');
  
    // modal 
    const [modalIsActive, setModalIsActive] = useState(false);
    const [modalProgress, setModalProgress] = useState(0);

    let { draftId } = useParams();
    const advSt = () => {
    setAppState(appState + 1);
    }

    const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    };

    const handleSubmit = async (e) => {
    e.preventDefault();  //to prevent page reload (it will refresh when click submit which is undesirable)
    const formData = new FormData(e.target); // Use name attributes on your form fields  (like in child node) so that FormData(event.target) automatically gathers them.
    if (selectedFile) {
        formData.append('file', selectedFile);
        console.log(formData);
    }
    if (!selectedFile && appState === 0){
        alert("Please select a file before submitting!");
        return
    }

     formData.append('appState', appState)
     formData.append('draftId', parseInt(draftId))
 
     try {
       await ensureHeaders()
       const response = await axios.post(API_URL + 'motiondismiss', formData);
       // Axios automatically handles the conversion of the response to JSON
       const result = response.data;
       if (response.status === 200) {
         setTaskId(result.result_id);
         console.log('Task submitted successfully');
         setModalIsActive(true); // Activate the modal only after a successful response
         setModalProgress(5);
       } else {
         console.error('Form submission failed');
       }
     } catch (error) {
       if (error.response){
         // The request was made and the server responded with a status code
         alert(error.response.data.message); // Display the error message from the backend
       } else{
         console.error('Form submission failed', error);
       }
     }
     };
 

    // Function to check the task status
    const checkTaskStatus = async () => {
    try {
        console.log("checking the status");
        
        const response = await axios.get(`${API_URL}result/${taskId}`);
        console.log("Response data:", response.data);
        if (response.data.ready) {
        setTaskId(null); // Reset taskId
        console.log("the data is ready")
        console.log(response.data);

        setModalProgress(100);
        // update the file to display
        if ('file_name' in response.data.value) {
            setFileName(response.data.value.file_name);
            setKey(prevKey => prevKey + 1);
          }
        advSt();
        setModalIsActive(false); 

        setFas(response.data.value.fas || fas);
        setBackground(response.data.value.background || background);
        setStandard(response.data.value.standard || standard);
        setStandardCitations(response.data.value.standard_citations || standardCitations);
        setCoaDict(response.data.value.coa_dict_list || coaDict);
        console.log("Parent: setting coaDict to:", response.data.value.coa_dict_list);

        setStructure(response.data.value.structure || structure);
        setJustification(response.data.value.justification || justification);
        setBatches(response.data.value.batches || batches);

         // Ensure the first batch is correctly stored
        if (response.data.value.all_batches_arguments) {
            setBatchArguments(response.data.value.all_batches_arguments)}
        console.log("parent module setBatchArguments: ", batchArguments)

        if (response.data.value.batch_citations) {
            setBatchCitations(response.data.value.batch_citations)
            console.log("parent batch_citations: ", response.data.value.batch_citations)
          }

        setIntroduction(response.data.value.intro || introduction);
        setConclusion(response.data.value.conclusion || conclusion);
        

        }
    } catch (error) {
        console.error('Error checking task status:', error);
        setTaskId(null);
        setModalIsActive(false);
        alert(ERROR_MSG_500);
      }
    };

    // function to update background with new facts in step1
    const updateStep1Background = async (additionalFact) => {
        try {
        setModalIsActive(true);
        setModalProgress(5);
    
        await ensureHeaders();
        const formData = new FormData();
        formData.append('draftId', draftId);
        formData.append('background', background);
        formData.append('newfas', additionalFact)
    
        const response = await axios.post(`${API_URL}motiondismiss/state1update`, formData);
    
        if (response.status === 200 && response.data?.background) {
            // This is the updated background from the LLM
            setBackground(response.data.background);
        }
        } catch (err) {
        console.error("Error in partial update:", err);
        alert("Error updating background. Please try again.");
        } finally {
        setModalIsActive(false);
        setModalProgress(0);
        }
    };

    // const updateStep3Structure = async (chosen_structure) => {
    //     try {
    //       setModalIsActive(true);
    //       setModalProgress(10);
    
    //       await ensureHeaders();
    //       setModalProgress(25);
    
    //       const formData = new FormData();
    //       formData.append('draftId', draftId);
    //       formData.append('chosen_structure', chosen_structure);
    
    //       setModalProgress(40);
    
    //       // Example endpoint: "motiondismiss/state4update"
    //       const response = await axios.post(`${API_URL}motiondismiss/state3update`, formData);
    
    //       setModalProgress(70);
    
    //       if (response.status === 200 && response.data?.batches) {
    //         // Overwrite the "Legal Standard" text with the LLM’s returned version
    //         setStructure(response.data.structure);
    //         setJustification(response.data.justification);
    //         setBatches(response.data.batches);
    //       }
    
    //       setModalProgress(100);
    //     } catch (err) {
    //       console.error("Error in partial update step3:", err);
    //       alert("Error updating structure. Please try again.");
    //     } finally {
    //       // small delay so user sees 100% for a moment
    //       setTimeout(() => {
    //         setModalIsActive(false);
    //         setModalProgress(0);
    //       }, 500);
    //     }
    //   };

      const fetchNextBatch = async (formData) => {
        try {
          setModalIsActive(true);
          console.log("Modal set to active");
          setModalProgress(10);
    
          await ensureHeaders();
          setModalProgress(25);

          // Call state4 endpoint to start the background task.
        const postResponse = await axios.post(API_URL + 'motiondismiss', formData);
        console.log("POST response:", postResponse.data);
    
        const taskIdFromResponse = postResponse.data.result_id;
        if (!taskIdFromResponse) {
        throw new Error("No task ID returned");
        }
    
        // Poll the result endpoint until the task is ready.
        let finalData;
        let pollingAttempts = 0;
        const maxAttempts = 20; // adjust as needed
        while (pollingAttempts < maxAttempts) {
        const resultResponse = await axios.get(`${API_URL}result/${taskIdFromResponse}`);
        console.log("Polling response:", resultResponse.data);
        if (resultResponse.data.ready) {
            finalData = resultResponse.data;
            break;
        }
        pollingAttempts++;
        // Wait for a short period before polling again.
        await new Promise((resolve) => setTimeout(resolve, 5000)); // 5 seconds
        }
    
        if (!finalData) {
        throw new Error("Task did not complete in time");
        }
    
        // Now, if the result is successful and has batch data, update state.
        if (finalData.successful && finalData.value) {
            setBatchArguments(finalData.value.all_batches_arguments)
            console.log("FetchNextBatch in parent: setBatchArguments: ", batchArguments)
            setBatchCitations(finalData.value.batch_citations)
            console.log("FetchNextBatch in parent: setBatchCitations: ", batchCitations)
            // NEW: Update fileName if available, similar to checkTaskStatus.
            if (finalData.value.file_name) {
                setFileName(finalData.value.file_name);
                // Changing the key forces the <embed> to reload the new file
                setKey(prevKey => prevKey + 1);
            }
          }
    
          setModalProgress(100);
          // Return an object with success + the final data
        return {
            success: true,
            data: finalData  // <= pass the entire finalData to the caller
        };
        } catch (err) {
            console.error("Error fetching next batch:", err);
            alert("Error generating next argument section. Please try again.");
        } finally {
            setTimeout(() => {
            setModalIsActive(false);
            setModalProgress(0);
            }, 500);
        }
    };
      
    // Start polling when taskId is set
    useEffect(() => {
        let intervalId;
        if (taskId) {
            intervalId = setInterval(() => {
                checkTaskStatus();
                let delta = appState === 0 ? 7 : 5;
                setModalProgress(progress => Math.min(progress + delta, 100));
            }, 5000);
        }
        return () => clearInterval(intervalId);
    }, [taskId, modalProgress]);  // Added modalProgress
  
  
      // Array to hold tab data
      const tabs = [
        { icon: mdiTrayArrowUp, text: 'Upload' },
        { icon: mdiFileDocumentEditOutline, text: 'Background & standard' },
        { icon: mdiFileDocumentEditOutline, text: 'Grounds' },
        // { icon: mdiFileDocumentEditOutline, text: 'Structure' },
        { icon: mdiFileDocumentEditOutline, text: 'Arguments' },
        { icon: mdiFileDocumentEditOutline, text: 'Intro & Conclusion'},
        { icon: mdiTrayArrowDown, text: 'Download' }
    ];

        return (
            <div className="App">
                <div className="split left">
                    <div className="dropdown-container" style={{margin: '20px 32px'}}>
                    <div className="tabs is-toggle is-progress">
                        <ul>
                            {tabs.map((tab, index) => (
                                <li key={index} className={index === appState ? 'is-active' : ''}>
                                <a  onClick={() => setAppState(index)} style={{ padding: '8px', display: 'inline-flex', alignItems:'center' }}>
                                    <span className="icon is-small">
                                      <Icon path={tab.icon} size={1} />
                                    </span>
                                    <span>{tab.text}</span>
                                </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                        <div>
                            {appState === 0 && <Step0 onSubmit={handleSubmit} onChange={handleFileChange} fileName={selectedFile}/>}
                            {appState === 1 && <Step1 onSubmit={handleSubmit} 
                            fas={fas} 
                            background={background} 
                            setFas={setFas}
                            setBackground={setBackground}
                            standard={standard} 
                            setStandard={setStandard}
                            // pass the partial update callback
                            onPartialUpdate={updateStep1Background}/>}

                            {/* {appState === 2 && <Step2 onSubmit={handleSubmit} 
                           
                            // pass the partial update callback
                            onPartialUpdate={updateStep2Standard}
                             />} */}
                            {appState === 2 && <Step2 onSubmit={handleSubmit} coaDict={coaDict} />}
                            {/* {appState === 3 && <Step3 onSubmit={handleSubmit} 
                            structure={structure} justification={justification} batches={batches} 
                            onPartialUpdate={updateStep3Structure}  
                            />} */}
                            {appState === 3 && <Step3 onSubmit={handleSubmit}  
                            batchArguments={batchArguments} 
                            batchCitations={batchCitations}
                            setBatchCitations={setBatchCitations}
                            fetchNextBatch={fetchNextBatch} 
                            draftId={draftId} />}
                            {appState === 4 && <Step4 onSubmit={handleSubmit} 
                            introduction={introduction}     
                            conclusion={conclusion}/>}
                            {appState === 5 && <Step5 draftId={draftId}/>}
                        </div>
                    </div> 
                    <ModalWithProgressBar isActive={modalIsActive} progress={modalProgress} />
                </div>
                <div className="split right">
                    <embed key={key} src={fileName} width={"100%"} height={2000} />
                </div>
            </div>
          );
        }
        
export default MotionDismiss;