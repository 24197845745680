// Step2.js

import React, { useState } from 'react';
import Icon from '@mdi/react';
import { mdiPlus, mdiChevronDown, mdiChevronUp, mdiOpenInNew } from '@mdi/js';
import '../global.css';

// New Collapsible Citations Component
const CaseCitations = ({ citations = [] }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (citations.length === 0) return null;

  // Dynamic header: simply count citations and add "related case(s)"
  const headerText = `Citation`;

  return (
    <div style={{ marginTop: '16px', border: '1px solid #CDD5DF', borderRadius: '8px', overflow: 'hidden' }}>
      <div
        onClick={() => setIsExpanded(!isExpanded)}
        className="text-left flex items-center justify-between"
        style={{ backgroundColor: '#EEF2F6', padding:'8px 12px', width:'100%', alignItems:'center',display: 'flex', cursor: 'pointer' }}
      >
        <span className="p" style={{ flexGrow: 1 }}>{headerText}</span>
        {isExpanded ? (
          <Icon path={mdiChevronUp} size={0.9} style={{ verticalAlign: 'middle', color: 'var(--color-font)' }}/>
        ) : (
          <Icon path={mdiChevronDown} size={0.9} style={{ verticalAlign: 'middle', color: 'var(--color-font)' }}/> 
        )}
      </div>
      
      {isExpanded && (
        <div style={{ padding:'8px 12px' }}>
          <div>
            <ul style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              {citations.map((citation, index) => (
                <li key={index} className="flex items-start">
                  <a
                    href={citation.type === 'state'
                      ? citation.prefix
                      : `${citation.prefix}/${citation.citationName}${citation.id ? `&${citation.id}` : ''}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p"
                    style={{ display:'inline-flex', alignItems: 'center', color: 'var(--color-primary)' }}
                  >
                    {citation.displayName}
                    <Icon
                    path={mdiOpenInNew}
                    size={0.7}
                    style={{ verticalAlign: 'middle', marginLeft: '6px', color: 'var(--color-primary)' }}
                  />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

function Step2({onSubmit, advSt, initialAds}) {
  const [textAreas, setTextAreas] = useState(initialAds); // Start with passed in values

  function addTextArea() {
    setTextAreas([...textAreas, '']); // Add a new empty textarea
  }

  return (
    <div>
      <h2 className='title is-4'>Suggested Affirmative Defenses</h2>
      <form onSubmit={onSubmit}>
        <div className='block'>
          {textAreas.map((afirmDef, index) => (
            <div key={index} className="box is-draft">
              <h4 className="title is-5">Affirmative Defense {index + 1}</h4>
              <textarea className="textarea" id={`ad${index}`} name={`ad${index}`}value={afirmDef.name} rows="2" style={{ marginBottom: '24px'}}/>
              <textarea className="textarea" value={afirmDef.descr} rows="4" />
              {/* Citations with collapsible citations component */}
              <CaseCitations citations={afirmDef.citations} />
              <div className="field">
                <label htmlFor={`ignore-ad${index}`}>
                <input className="is-checkradio" type="checkbox" id={`ignore-ad${index}`} name={`ignore-ad${index}`}/>
                Ignore this Affirmative Defense
                </label>
              </div>
            </div>
          ))}
        </div>
        <div className='block'>
          <button type="button" className="button is-small is-secondary" onClick={addTextArea}>
            <span className='icon'>
              <Icon path={mdiPlus} size={1} />
            </span>
            <span>
              Add Affirmative Defense
            </span>
          </button>
        </div>

        <div className="has-text-right">
          <button className="button is-normal is-success" type="submit">Next: Counterclaim</button>
        </div>
      </form>
      {/* <div className="has-text-right">
        <button className="button is-normal is-success" type="button" onClick={advSt}>Next Step: Counter Claims</button>
      </div> */}
    </div>
  );
}

export default Step2;

