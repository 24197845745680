// Step1.js

import React, { useState } from 'react';
import Icon from '@mdi/react';
import { mdiPlus, mdiChevronDown, mdiChevronUp, mdiOpenInNew } from '@mdi/js';
import '../global.css';

function Step1({ onSubmit, advSt, initialCoas }) {
  // Our state is an array of objects, each with { "CauseName": { ... } }
  const [coas, setCoas] = useState(initialCoas);

  function addTextArea() {
    // Add a brand new object with a placeholder cause name and empty fields
    setCoas([
      ...coas,
      {
        "New Cause of Action": {
          description: "Enter a description here",
          citations: [],
          filtered_citations: [],
          filtered_citations_info: []
        }
      },
    ]);
  }

  return (
    <div>
      <div className="block">
        <h3 className="title is-4">Causes of Action</h3>
      </div>
      <form onSubmit={onSubmit}>
        <div className="block">
          {coas.map((obj, index) => {
            // Each 'obj' is something like: { "Patent Infringement": { description, citations, ... } }
            const causeName = Object.keys(obj)[0]; // e.g. "Patent Infringement"
            const causeData = obj[causeName];      // e.g. { description, citations, ... }

            return (
              <div key={index} className="box is-draft">
                <h3 className="title is-6">Cause of Action {index + 1}</h3>

                {/* Cause name (editable) */}
                <div style={{ paddingLeft: '4px', paddingBottom: '4px' }}>
                  <label
                    className="card-header-title title is-7"
                    htmlFor={`coa${index}-Name`}
                    style={{ margin: '0px', display: 'block' }}
                    >
                    {causeName}
                  </label>
                </div>
                
                {/* Description (read-only for the example) */}
                <label className="label">Rationale</label>
                <textarea
                  className="textarea"
                  id={`coa${index}-Description`}
                  name={`coa${index}-Description`}
                  defaultValue={causeData.description}
                  rows="4"
                  style={{ marginBottom: '8px'}}
                />

                {/* Links from filtered_citations_info */}
                  <label className="label" style={{ paddingBottom: '2px' }}>Citation</label>
                    <ul style={{ marginBottom: '16px', marginLeft: '4px'}}>
                      {causeData.filtered_citations_info.map((info, idx) => {
                        // Example link: prefix + '/' + citationName => https://www.law.cornell.edu/uscode/text/35/271
                        const href = (info.type === 'state' 
                          ? info.prefix 
                          : `${info.prefix}/${info.citationName}${info.id ? `&${info.id}` : ''}`)
                        return (
                          <li key={idx} className="flex items-start" style={{ marginBottom: '2px' }}>
                            <a 
                              href={href}
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                display: 'inline-flex',
                                alignItems: 'flex-start',
                                color: 'var(--color-primary)'
                              }}
                            >
                              <Icon
                                path={mdiOpenInNew}
                                size={0.7}
                                className="fixed-icon" // Add a specific class
                                style={{
                                  marginTop: '3px',
                                  marginRight: '6px',
                                  color: 'var(--color-primary)',
                                }}
                              />
                              {causeData.filtered_citations[idx]}
                            </a>
                          </li>
                        );
                      })}
                    </ul>

                {/* Ignore this COA? */}
                <div className="field">
                  <label className="checkbox" htmlFor={`ignore-coa${index}`}>
                    <input
                      className="is-checkradio"
                      type="checkbox"
                      id={`ignore-coa${index}`}
                      name={`ignore-coa${index}`}
                    />
                    &nbsp; Ignore this Cause of Action
                  </label>
                </div>
              </div>
            );
          })}
        </div>

        {/* Add a brand new cause */}
        <div className="block">
          <button
            type="button"
            className="button is-small is-secondary"
            onClick={addTextArea}
          >
            <span className="icon">
              <Icon path={mdiPlus} size={1} />
            </span>
            <span>Add Cause of Action</span>
          </button>
        </div>

        {/* Submit button */}
        <div className="block has-text-right">
          <button className="button is-normal is-success" type="submit">
            Update draft and continue
          </button>
        </div>
      </form>
    </div>
  );
}

export default Step1;
