
import React, { useState, useEffect } from 'react';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import '../global.css';


function Step3({onSubmit, advSt, responses}) {
  const [textAreas, setTextAreas] = useState(responses);
  const [selectedValues, setSelectedValues] = useState({req0:'Object', req1:'Full Production', req2:'No Documents', req3:'Customize'})

  function addTextArea() {
    setTextAreas([...textAreas, '']); // Add a new empty textarea
  }

  const handleSelectChange = (id, value) => {
    setSelectedValues(prevValues => ({
      ...prevValues,
      [id]: value
    }));
  };

  return (
    <div>
      <div className='block'><h3 className="title is-4" style={{ marginBottom: '8px' }}>Specific Objections and Responses</h3></div>
      <form onSubmit={onSubmit}>
        <div className='block'>
          {textAreas.map((text, index) => (
            <div key={index} className="box is-draft">
              <label htmlFor={`req-${index}`} className="label">Request No. {index + 1}</label>
              <div>{text.request}</div> {/* Display the request content here */}

              {/* Display the objection as a textarea if it's not an empty string */}
             {text.objection && text.objection.trim() !== '' && (
                <div>
                  <h3 className="title is-6" style={{ marginTop: '8px' }}>Suggested Objection</h3>
                  <textarea
                    className="textarea"
                    id={`objection-${index}`} // Unique ID for the textarea
                    name={`objection-${index}`} // Unique name for the textarea
                    defaultValue={text.objection} // Set the default value to the objection text
                    rows="4"
                    style={{ marginBottom: '24px' }}
                  />
                </div>
              )}
              {/* Set the selector to 'Object' if there is objection content */}
              <select 
              id={`req-${index}`} 
              name={`req-${index}`} 
              onChange={(e) => handleSelectChange(`req-${index}`, e.target.value)} 
              className='select'
              defaultValue={text.objection ? 'Object' : 'Full Production'}
              >
                <option value="Object">Object</option>
                <option value="Full Production">Full Production</option>
                <option value="No Documents">No Documents</option>
                <option value="Custom">Custom</option>
              </select>

              {selectedValues[`req-${index}`] === 'Custom' ? (
                <textarea
                  className="textarea"
                  type="text"
                  placeholder="Short note about partial production or referencing prior doc productions"
                  name={`custom-${index}`} // Unique name for the textarea
                  style={{ marginTop: '8px' }}
                />
              ) : null
            }
              
            </div>
          ))}
        </div>
        
        <div className='block has-text-right'>
          <button className="button is-normal is-success" type="submit">Update Draft</button>
        </div>
      </form>

    </div>
  );
}

export default Step3;
