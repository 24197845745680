import React, { useState } from 'react';

function Step5({ onSubmit, introduction, setIntroduction, conclusion, setConclusion, draftId }) {

    return (
        <form onSubmit={onSubmit}>
            <div className="card box is-draft" style={{ padding: '16px', marginTop: '16px' }}>
                <h3 className="title is-7" style={{ marginBottom: '8px' }}>
                    Introduction
                </h3>
                <label className='label' htmlFor="introBody">Suggested Introduction:</label>
            <textarea 
            className="textarea" 
            id="introduction" 
            name="introduction" 
            defaultValue={introduction} 
            rows={12} style={{ width: '100%', maxWidth: '540px' }} 
            />
            </div>
            <div className="card box is-draft" style={{ padding: '16px', marginTop: '16px' }}>
                <h3 className="title is-7" style={{ marginBottom: '8px' }}>
                    Conclusion
                </h3>
                <textarea
                    name="conclusion"
                    className="textarea"
                    defaultValue={conclusion}
                    rows={12}
                    style={{ width: '100%', maxWidth: '540px' }} 
                />
            </div> 

            <div className="has-text-right" style={{ marginTop: '16px' }}>
                <button className="button is-normal is-success" type="submit">
                    Save and Review
                </button>
            </div>
        </form>
    );
}

export default Step5;
