// Navbar.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import Icon from '@mdi/react';
import { mdiHomeVariantOutline, mdiAccountCircleOutline } from '@mdi/js';

function Navbar({handleLogout}) {
  const location = useLocation();
  const researchTabRef = React.useRef(null);

  const handleResearchClick = () => {
    if (location.pathname !== '/research') {
      if (researchTabRef.current && !researchTabRef.current.closed) {
        try {
          // Check if the research tab's current URL is '/research'
          if (researchTabRef.current.location.pathname === '/research') {
            researchTabRef.current.focus();
            return;
          }
        } catch (error) {
          console.error('Error checking research tab location:', error);
        }
      }
      // Either no tab exists, or the tab is not at /research
      researchTabRef.current = window.open('/research', '_blank');
    }
  };

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
        <img src="lextext-logo.svg" alt="Logo" className="logo" />
      </Link>
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
        <button className="navbar-button" onClick={handleResearchClick}>
          Legal Research
          <span className='navbar-tag'>
            Beta
          </span>
        </button>  
        <div className="navbar-links" >
          <>
            <Link to="/" className="navbar-item">
              <span className="icon is-navbar" style={{ width:'28px', height:'28px', margin:'0px 12px'}}>
                  <Icon path={mdiHomeVariantOutline} style={{ width: '100%', height: '100%' }} />
              </span>
            </Link> 
            <div className="navbar-end">
              <div className="navbar-item has-dropdown is-hoverable is-right">
                <a className="navbar-link">
                  <span className="icon is-navbar" style={{ width:'28px', height:'28px'}}>
                    <Icon path={mdiAccountCircleOutline} style={{ width: '100%', height: '100%' }} />
                  </span>
                </a>
                <div className="navbar-dropdown">
                  <Link to="/profile" className="navbar-item">
                    <div className='navbar-label'>
                      Profile
                    </div>
                  </Link>
                  <hr className="navbar-divider" />
                  <a onClick={handleLogout} className="navbar-item">
                    <div className='navbar-label'>
                      Log out
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
