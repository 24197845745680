// Step2.js

import React, { useState } from "react";
import '../global.css';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp, mdiBullseyeArrow, mdiGlassFragile, mdiOpenInNew } from '@mdi/js';

function Step2({onSubmit, inputObj}) {

  // State to track visibility of each card content
  const [visibleCards, setVisibleCards] = useState(
    Object.fromEntries(inputObj.map(([causeOfAction]) => [causeOfAction, false]))
  );

  // Toggle visibility function
  const toggleVisibility = (causeOfAction) => {
    setVisibleCards((prevVisibleCards) => ({
      ...prevVisibleCards,
      [causeOfAction]: !prevVisibleCards[causeOfAction] // Toggle the visibility
    }));
  };
  

  return (
    <form onSubmit={onSubmit}>
      <div className="block">
        <h1 className="title is-4" style={{ marginBottom: '8px' }}>Legal Element Assessment</h1>
      </div>
      <div>

        {inputObj.map(([causeOfAction, value]) => (
          <div key={causeOfAction} className="card box is-draft" style={{ padding: '0' }}>
            <header className={`card-header ${visibleCards[causeOfAction] ? 'is-expanded' : ''}`} style={{ boxShadow: 'none' }}>
              <h3 className="card-header-title title is-7" style={{ margin: "0px 0px 0px 16px" }}>{causeOfAction}</h3>
              <button type="button" className="card-header-icon" aria-label="toggle visibility" onClick={() => toggleVisibility(causeOfAction)}>
                <Icon path={visibleCards[causeOfAction] ? mdiChevronUp : mdiChevronDown} size={1} />
              </button>
            </header>
            {visibleCards[causeOfAction] && (
              <div className="card-content" style={{ padding: '0px 24px 12px 24px' }}>
                <div className="content">
                  {/* {value.description && (
                    <p className="body" style={{ marginBottom: '20px' }}>{value.descrpiton}</p>
                  )} */}

                  {value.elements.map((element, index) => (
                    <div key={index} className="table-row" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <div style={{ display: 'flex', alignItems: 'center', width: '100%', padding: '0px 8px' }}>
                        {/* <b className="body" style={{ fontWeight: '600', flex: 1, display: 'flex', alignItems: 'center', paddingRight:'16px' }}>
                        {index+1}. {element.name} - {element.source}
                        </b> */}
                        {/* Legal elements and citations */}
                        <b className="body" style={{ fontWeight: '600', flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingRight: '24px' }}>
                          <span style={{ marginBottom: '8px' }}>{index+1}. {element.name}</span>
                          {Array.isArray(element.source) ? (
                            element.source.map((source, i) => {
                              const citation = element.source_info && element.source_info[i];
                              return (
                                <div key={i} className="citation" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '4px'}}>
                                  <div style={{ width: '1em', flexShrink: 0, marginLeft: '0.5em' }}>{'•'}</div>
                                  <div style={{ flex: 1 }}>
                                    {citation ? (
                                      <a
                                        href={
                                          citation.type === 'state'
                                            ? citation.prefix
                                            : `${citation.prefix}/${citation.citationName}${citation.id ? `&${citation.id}` : ''}`
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {source}
                                        <Icon 
                                          path={mdiOpenInNew}
                                          size={0.7}
                                          style={{ marginLeft: '6px', verticalAlign: 'middle' }}
                                          aria-label="Open in new window"
                                        />
                                      </a>
                                    ) : (
                                      source
                                    )}
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div style={{ display: 'flex', alignItems: 'flex-start', fontSize: '0.9em', opacity: '0.8' }}>
                              <div style={{ width: '1em', flexShrink: 0 }}>{'•'}</div>
                              <div style={{ flex: 1 }}>{element.source}</div>
                            </div>
                          )}
                        </b>
                        <span>
                          {element.satisfied ? (
                            <span className="tag met" disabled>Likely met</span>
                          ) : (
                            <span className="tag not-met" disabled>Caution</span>
                          )}
                        </span>
                      </div>
                      
                      <div style={{ width: '100%' }}>
                        <div>
                        {!element.satisfied && element.analysis && ( //focus here.
                          <div style={{ marginTop: "12px", marginBottom: "8px", backgroundColor: "#F5F5F5", borderRadius: "8px", padding: "8px 12px" }}>
                            {element.analysis.split("\n").map((line, index) => {
                              let heading = "";
                              let iconPath = null;
                              if (index === 0) {
                                heading = "Weakness";
                                iconPath = mdiGlassFragile;
                              } else if (index === 1) {
                                heading = "Focus of Challenge";
                                iconPath = mdiBullseyeArrow;
                              } else {
                                heading = "Other"; // for lines beyond the second
                              }
                        
                              return (
                                <div key={index} className="list-legal">
                                  <div style={{ display: "flex", alignItems: "center" }}>
                                    {iconPath && (
                                      <Icon path={iconPath} size={0.9} aria-hidden="true" style={{ paddingRight: '4px', color: '#B29F7B' }}/>
                                    )}
                                    <p className="p bold">{heading}</p>
                                  </div>
                                  <p style={{ margin: 0 }}>{line}</p>
                                </div>
                              );
                            })}

                            {/* add citation info from weakness and focus of challenge */}
                            {element.weakness_cit_info && element.weakness_cit_info.length > 0 && (
                              <ul>
                                {element.weakness_cit_info.map((weakness_citation, cIndex) => (
                                  <li key={index}>
                                    <a 
                                      href={weakness_citation.type === 'state' 
                                        ? weakness_citation.prefix 
                                        : `${weakness_citation.prefix}/${weakness_citation.citationName}${weakness_citation.id ? `&${weakness_citation.id}` : ''}`} 
                                      target="_blank" 
                                      rel="noopener noreferrer"
                                    >
                                      {weakness_citation.displayName}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        )}
                        </div>  
                      </div>                     
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

        <div className="has-text-right" style={{ marginTop: "24px" }}>
          <button className="button is-normal is-success" type="submit">View recommendations</button>
        </div>
      </form>
  );
}

export default Step2;