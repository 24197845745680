import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bulma/css/bulma.min.css';
import './App.css';
import Navbar from './Navbar';
import AnswerDrafter from './answer/AnswerDrafter.js';
import ComplaintDrafter from './complaint/ComplaintDrafter.js';
import RfpDrafter from './rfp/RfpDrafter.js';
import RogDrafter from './rog/RogDrafter.js';
import RfaDrafter from './rfa/RfaDrafter.js';
import ObjectionsDrafter from './objections/ObjectionsDrafter.js';
import ElementChecker from './elementchecker/ElementChecker.js';
import Profile from './auth/Profile.js';
import LoggedInLanding from './LoggedInLanding.js';
import awsConfig from './amplify-config.js';
import { Authenticator, View, Heading, Card } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import TokenFetcher from './auth/TokenFetcher.js';
import CaseViewer from './CaseViewer.js';
import Research from './research/Research.js';
import MotionDismiss from './dismiss/MotionDismiss.js';
import Analytics from './Analytics.js';

Amplify.configure(awsConfig);

function App() {

  return (
    <>
    <TokenFetcher />
      <Card>
          <Authenticator hideSignUp components={{
            SignIn: {
              Header() {
                return (
                  <View class="auth-view">
                    <div class="auth-logo" alt="LexText logo"></div>
                    <Heading level={3}>Log in to your account</Heading>
                  </View>
                );
              }
            }}}>

            {({ signOut, user }) => (
              <Router>
                <Navbar handleLogout={signOut} />
                <Routes>
                  <Route exact path="/" element={<LoggedInLanding />} />
                  <Route path="/AnswerDrafter/:draftId" element={<AnswerDrafter />} />
                  <Route path="/ComplaintDrafter/:draftId" element={<ComplaintDrafter />} />
                  <Route path="/RfpDrafter/:draftId" element={<RfpDrafter />} />
                  <Route path="/RogDrafter/:draftId" element={<RogDrafter />} />
                  <Route path="/RfaDrafter/:draftId" element={<RfaDrafter />} />
                  <Route path="/ObjectionsDrafter/:draftId" element={<ObjectionsDrafter />} />
                  <Route path="/ElementChecker/:draftId" element={<ElementChecker />} />
                  <Route path="/MotionDismiss/:draftId" element={<MotionDismiss />} />
                  <Route path="/CaseViewer/:citationInfo" element={<CaseViewer />} />
                  <Route path="/Research" element={<Research />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/analytics" element={<Analytics />} />
                </Routes>
              </Router>
            )}
          </Authenticator>
      </Card>
    </>
  );

}

export default App;
