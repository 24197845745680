import React, { useState, useEffect, useRef } from "react";
import "./Research.css";
import axios from "axios";
import { API_URL } from "../Constants";
import { ensureHeaders } from '../auth/useSetHeaders';
import { marked } from "marked";

const ERROR_MSG = "There was an error submitting the form. Please check your inputs. If the problem persists, contact support.";

const Research = () => {
  const [input, setInput] = useState("");
  const textareaRef = useRef(null);
  const [results, setResults] = useState([]); // Store user inputs and API responses
  const [taskId, setTaskId] = useState(null); 
  const [modalIsActive, setModaIsActive] = useState(false);
  const [modalProgress, setModalProgress] = useState(0);

  // Auto resize Input Form
  const resizeTextarea = (element) => {
    if (element) {
      element.style.height = 'auto';
      element.style.height = `${element.scrollHeight}px`;
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    resizeTextarea(e.target);
  };

  // Handle resizing when input changes (including clearing)
  useEffect(() => {
    resizeTextarea(textareaRef.current);
  }, [input]);
  
  // Initial sizing effect can remain
  useEffect(() => {
    resizeTextarea(textareaRef.current);
  }, []);

  useEffect(() => {
    const textarea = document.querySelector('.auto-resize');
    resizeTextarea(textarea);
  }, []);

  // Auto srolls to bottom when results change
  useEffect(() => {
    const resultsContainer = document.querySelector('.results-container');
    
    if (resultsContainer) {
      resultsContainer.scrollTo({
        top: resultsContainer.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, [results]);

  // Allow prompt submission by hitting "Enter"
  const handleKeyDown = (e) => {
    // Check if Enter was pressed without Shift key
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent default behavior (newline)
      handleSubmit(e); // Submit the form
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!input.trim()) {
      return; // Do nothing if input is empty
    }

    const newEntry = { input, response: "" };
    const conversationHistory = [...results, newEntry];
    setResults(conversationHistory);

    try {
      // Call the backend API
      await ensureHeaders();
      const response = await axios.post(API_URL + "research", {
        query: input,
        conversation: conversationHistory,  // new tool
      });

      const result = response.data;
      if (response.status === 200) { // Check if the request was successful
        setTaskId(result.result_id);
        console.log('Task submitted successfully');
      } else {
        console.error('Form submission failed');
        setTaskId(null);
        setModaIsActive(false);
        alert(ERROR_MSG);
      }

    } catch (error) {
      console.error("Error calling the API:", error);
      // Optionally, handle errors (e.g., show an error message)
      setResults((prevResults) => [
        ...prevResults,
        { input, response: "Error fetching data. Please try again." },
      ]);
    }
  };

  // Function to check the task status
  const checkTaskStatus = async () => {
    try {
      console.log("checking the status");
      const response = await axios.get(`${API_URL}result/${taskId}`);
      if (response.data.ready) {
        setTaskId(null); // Reset taskId
        console.log("the data is ready");

      // Append user input and API result to the results array
      setResults((prevResults) => {
        const updatedResults = [...prevResults];
        updatedResults[updatedResults.length - 1].response =
          response.data.value.response;
        return updatedResults;
      });

      setInput(""); // Clear the input field after submission

        if ('response' in response.data.value){
          console.log("response is going to be set.");
        }
        setModalProgress(100);
        setModaIsActive(false);
      }
    } catch (error) {
      console.error('Error checking task status:', error);
      setTaskId(null);
      setModaIsActive(false);
      alert("There was an error, please contact customer support at support@lextext.ai .");
    }
  };

  // Start polling when taskId is set
  useEffect(() => {
    let intervalId;
    if (taskId) {
      intervalId = setInterval(() => {
        checkTaskStatus();
        let delta = 5;
        setModalProgress(modalProgress => modalProgress + delta);
      }, 5000); // Poll every 5000 milliseconds (5 seconds)
    }
    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [taskId]);

  return (
    <div className="research-container">

      {/* Results Section */}
      <div className={`results-container ${results.length === 0 ? 'empty-results' : ''}`}>
        {results.map((result, index) => (
          <div key={index} className="result-item">
            <div className="user-message-container">
              <div className="user-message-bubble">
                <p>{result.input}</p>
              </div>
            </div>
            <div
              className="markdown-response"
              dangerouslySetInnerHTML={{ __html: marked(result.response) }} 
            />
          </div>
        ))}
      </div>

      {/* Input Form */}
      <form className={`input-form ${results.length === 0 ? 'centered-form' : ''}`} onSubmit={handleSubmit}>
        <textarea
          ref={textareaRef}
          className="chat-input auto-resize"
          placeholder="Type your research question..."
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          rows="1"
        />
        <button type="submit" className="button is-primary" disabled={taskId !== null}>
          Send
        </button>
      </form>
    </div>
  );
};

export default Research;