import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from './Constants';
import { ensureHeaders } from './auth/useSetHeaders';
import './global.css';
import Icon from '@mdi/react';
import { mdiAccountMultipleOutline, mdiBankOutline, mdiOpenInNew, mdiFileDocumentOutline, mdiCommentQuoteOutline, mdiWeb, mdiFormatListBulleted, mdiGavel } from '@mdi/js';

const CaseViewer = () => {
  const { citationInfo } = useParams(); // Get the citationName and cseId from the URL, // Extract the combined parameter
  console.log("Citation Info:", citationInfo);
  //const [citationName, caseId] = citationInfo.split('&'); // Split into citationName and caseId
  // Safely split citationInfo into citationName and caseId
  let citationName, caseId;
  if (citationInfo.includes('&')) {
    [citationName, caseId] = citationInfo.split('&'); // Split into citationName and caseId
  } else {
    citationName = citationInfo;
    caseId = null; // Set caseId to null if not present
  }
  const [citationData, setCitationData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Check if all sub-section values are empty
  const isSectionEmpty = (items) => {
    return items.every((item) => !item.body); // Returns true if all sub-sections are empty
  };

  // CardSubContent Component
  const CardSubContent = ({ title, iconPath, children, isHidden }) => {
    if (isHidden) return null; // Hide the entire main section if it's empty
    return (
      <div className="card-subcontent">
        <div className="card-subheader">
          <div className="icon-container">
            <Icon path={iconPath} size={0.85} color="var(--color-primary)" />
          </div>
          <div className="card-subheader title is-7" style={{ color:'var(--color-font)'}}>{title}</div>
        </div>
        {children}
      </div>
    );
  };

  // CaseDetailItem Component
  const CaseDetailItem = ({ header, body }) => {
    if (!body) return null; // Hide sub-section if no value is provided
    return (
      <div className="case-detail-item">
        <h4 className="case-detail-header p bold no-margin">{header}</h4>
        <p className="case-detail-body">{body}</p>
      </div>
    );
  };

  useEffect(() => {
    const fetchCitationData = async () => {
      if (citationName === 'demo') {
        // Load hardcoded citationData if the URL parameter is 'demo'
        const hardcodedData = {
          freelaw_link: "https://example.com/freelaw",
          casetext: "https://example.com/casetext",
          llm_hist: "- **Case Summary:** This is a demo case summary.",
          opinion: {
            html_with_citations: "<p>This is a demo opinion with <strong>citations</strong>.</p>",
            citation: "Here is a citation",
            type: "Majority",
            date_filed: "2023-02-01",
            author: "Mark Twain",
            attorneys: "Dan Argue, Mark Phibbs, Johnny Cocraine",
            related_cases: ["C061999", "C1999Bone"]
          },
          cluster: {
            case_name: "Demo Case Name",
            docket_number: "5150-12344",
            date_filed: "2023-01-01",
            nature_of_suit: "the nature of this suit is not-natural",
            court: "California Santa Barbara",
            precedential_status: "precedent",
            citations: [{'volume': 676, 'reporter': 'F.3d', 'page': '19', 'type': 1}, {'volume': 102, 'reporter': 'U.S.P.Q. 2d (BNA)', 'page': '1283', 'type': 4}, {'volume': 2012, 'reporter': 'WL', 'page': '1130851', 'type': 7}, {'volume': 2012, 'reporter': 'U.S. App. LEXIS', 'page': '6909', 'type': 6}],
            judges: "Judge Ito, Brett Farve",
            amici: "some stuff goes here"
          }
        };
        setCitationData(hardcodedData);
        setLoading(false);
      } else {
        // Fetch real citation data from API
        await ensureHeaders();
        try {
          const url = caseId
            ? `${API_URL}opinions/${citationName}&${caseId}`
            : `${API_URL}opinions/${citationName}`;
          console.log("citationName:", citationName, "caseId:", caseId);

          const response = await axios.get(url);
          setCitationData(response.data);
          console.log(response.data);
        } catch (error) {
          console.error("Error fetching citation data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchCitationData();
  }, [citationName, caseId]);

  function getCitationInfo(citations) {
    let officialCitation = '';
  
    citations.forEach(c => {
      if (c.type === 1) {
        officialCitation = `${c.volume} ${c.reporter} ${c.page}`;
      }
    });
  
    // Fallback if no official citation is found
    if (!officialCitation && citations.length > 0) {
      const fallback = citations[0];
      officialCitation = `${fallback.volume} ${fallback.reporter} ${fallback.page}`;
    }
  
    return officialCitation;
  }
  

  return (
    <div className="App" style={{ height: 'calc(100vh - 80px)' }}>
      <div className="columns is-gapless" style={{ margin: '12px 40px 0px 40px', width: '100%', position: 'relative', justifyContent: 'center' }}>
        {/* Main content */}
        <div className="column" style={{ marginRight: '64px', maxWidth: '580px', height: '100%', display: 'flex', flexDirection: 'column' }}>
          <div className="dropdown-container" style={{ maxHeight: '100%', margin: '0px', display: 'flex', flexDirection: 'column', flex: '1', overflow:'hidden' }}>
            {loading ? (
              <p>Loading citation details...</p>
            ) : citationData ? (
              <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
                {/* Main header */}
                <div style={{ paddingBottom: '24px', paddingLeft: '8px', paddingRight: '8px', borderBottom: '1px solid #9AA4B2', position: 'sticky', top: 0, backgroundColor: '#F9FAFB', zIndex: 1, }}>
                <h1 className='title is-4' style={{ marginBottom: 0 }}>{citationData.cluster.case_name}</h1>
                {citationData.cluster.citations && (
                  <>
                    {getCitationInfo(citationData.cluster.citations)}
                  </>
                )}
                <p>{citationData.opinion.citation}</p>
              </div>
                
                {/* Main body */}
                <div style={{ paddingTop: '10px', flex: 1, overflowY: 'auto' }}>
                  {/* Also Available On */}
                  <div className='section'>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '6px', marginBottom: '16px'  }}>
                      <Icon path={mdiWeb} size={0.9} color='var(--color-primary)' />
                      <h4 className="title is-5">Also Available On</h4>
                    </div>
                    <div style={{ display: 'flex', gap: '8px', width: '100%' }}>  
                      {/* FreeLaw link here */}
                      {citationData.freelaw_link && (
                        <div>
                          <button
                            className="button is-link is-light"
                            style={{ display: 'flex', alignItems: 'center', borderRadius: '32px', padding: '6px 16px' }}
                            onClick={() => window.open(citationData.freelaw_link, '_blank', 'noopener noreferrer')}
                          >
                            <span className="icon">
                              <Icon path={mdiFileDocumentOutline} size={0.8} color="currentColor" />
                            </span>
                            <span>CourtListener</span>
                          </button>
                        </div>
                      )}
                      {/* casetext link here */}
                      {citationData.casetext && (
                        <div>
                          <button
                            className="button is-link is-light"
                            style={{ display: 'flex', alignItems: 'center', borderRadius: '32px', padding: '6px 16px' }}
                            onClick={() => window.open(citationData.casetext, '_blank', 'noopener noreferrer')}
                          >
                            <span className="icon">
                              <Icon path={mdiFileDocumentOutline} size={0.8} color="currentColor" />
                            </span>
                            <span>Casetext</span>
                          </button>
                        </div>
                      )}
                      {/* Westlaw link here */}
                      {citationData.cluster.case_name && (
                        <div>
                          <button
                            className="button is-link is-light"
                            style={{ display: 'flex', alignItems: 'center', borderRadius: '32px', padding: '6px 16px' }}
                            onClick={() => window.open('https://1.next.westlaw.com/Search/Home.html?query=' + encodeURIComponent(citationData.cluster.case_name), '_blank', 'noopener noreferrer')}
                          >
                            <span className="icon">
                              <Icon path={mdiFileDocumentOutline} size={0.8} color="currentColor" />
                            </span>
                            <span>Westlaw</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Brief Summary (LLM) */}
                  <div className='section'>
                    {citationData.llm_hist && (
                      <div>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '6px', marginBottom: '16px' }}>
                        <Icon path={mdiFormatListBulleted} size={0.9} color='var(--color-primary)' />
                        <h4 className='title is-5'>LexText Synopsis</h4>
                      </div>
                      {citationData.llm_hist
                        .split(/- \*\*|\. -/) // Split the text by Regex 
                        .map((line, index) => (
                          <p key={index}>{index > 0 ? `- **${line}` : line}</p> // Re-add the delimiter for all but the first line
                        ))}
                    </div>
                    )}
                  </div>
                  {/* Opinion */}
                  <div className='section'>
                    {citationData.opinion && (
                      <>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '6px', marginBottom: '16px'  }}>
                        <Icon path={mdiGavel} size={0.9} color='var(--color-primary)' />
                        <h4 className='title is-5'>Opinion</h4>
                      </div>
                      <div dangerouslySetInnerHTML={{ __html: citationData.opinion.html_with_citations }}>
                      </div>
                    </>
                    )}
                  </div>
                </div>
                
                {/* Display other citation information as needed */}
              </div>
            ) : (
              <p>No details found for citation {caseId}</p>
            )}
          </div>
        </div>
  
        {/* Bulma card on the right */}
        <div className="column is-narrow" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <div className="card" style={{ maxHeight: '100%', width: '320px', marginBottom: '48px', paddingBottom: '0px' }}>
            {/* Card header */}
            <header className="card-header" style={{ borderBottom: '1px solid #9AA4B2', position: 'sticky', top: '0', backgroundColor: '#fff', zIndex: 1 }}>
              <h1 class="title is-6" style={{ color: "var(--color-primary)", flex: "1 0 0" }}>Case Details</h1>
            </header>
            <div className="card-content" style={{ flex: 1, overflowY: 'auto', width: '100%' }}>
              <div className="content" style={{ width: '100%' }}>
                {citationData  ? (
                  <>
                    {/* Case Identification */}
                    <CardSubContent 
                      title="Case Identification"
                      iconPath={mdiFileDocumentOutline}
                      isHidden={isSectionEmpty([
                        { header: "Nature of Suit", body: citationData.cluster.nature_of_suit },
                        { header: "Docket Number", body: citationData.cluster.docket_number },
                      ])}>
                        <CaseDetailItem header="Nature of Suit" body={citationData.cluster.nature_of_suit} />
                        <CaseDetailItem header="Docket Number" body={citationData.cluster.docket_number} />
                    </CardSubContent>

                    {/* Court and Decision Information */}
                    <CardSubContent
                      title="Court and Decision Information"
                      iconPath={mdiBankOutline}
                      isHidden={isSectionEmpty([
                        { header: "Court", body: citationData.cluster.court },
                        { header: "Filed Date", body: citationData.cluster.date_filed },
                        { header: "Opinion Date Filed", body: citationData.opinion.date_filed },
                        { header: "Precedential Status", body: citationData.cluster.precedential_status },
                      ])}>
                        <CaseDetailItem header="Court" body={citationData.cluster.court} />
                        <CaseDetailItem header="Filed Date" body={citationData.cluster.date_filed} />
                        <CaseDetailItem header="Opinion Date Filed" body={citationData.opinion.date_filed} />
                        <CaseDetailItem header="Precedential Status" body={citationData.cluster.precedential_status} />
                    </CardSubContent>

                    {/* Citations */}
                    <CardSubContent
                      title="Citations"
                      iconPath={mdiCommentQuoteOutline}
                      isHidden={!citationData.cluster.citations || citationData.cluster.citations.length === 0}>
                        {citationData.cluster.citations.map((item, index) => {
                            // Generate the URL based on volume and reporter
                            const href = `https://example.com/${item.reporter}/${item.volume}`;
                            return (
                              <div key={index}>
                                  Volume {item.volume}, Reporter {item.reporter}
                              </div>
                            );
                          })}
                    </CardSubContent>
                    
                    {/* Judicial and Legal Personnel */}
                    <CardSubContent
                      title="Judicial and Legal Personnel"
                      iconPath={mdiAccountMultipleOutline}
                      isHidden={isSectionEmpty([
                        { header: "Judges", body: citationData.cluster.judges },
                        { header: "Authoring Judge", body: citationData.opinion.author },
                        { header: "Attorneys", body: citationData.opinion.attorneys },
                        { header: "Amici Curiae", body: citationData.cluster.amici },
                      ])}>
                        <CaseDetailItem header="Judges" body={citationData.cluster.judges} />
                        <CaseDetailItem header="Authoring Judge" body={citationData.opinion.author} />
                        <CaseDetailItem header="Attorneys" body={citationData.opinion.attorneys} />
                        <CaseDetailItem header="Amici Curiae" body={citationData.cluster.amici} />
                    </CardSubContent>

                    {/* Related Cases */}
                    <CardSubContent
                      title="Related Cases"
                      iconPath={mdiOpenInNew}
                      isHidden={!citationData.opinion.related_cases || citationData.opinion.related_cases.length === 0}>
                        {citationData.opinion.related_cases &&
                          citationData.opinion.related_cases.map((caseItem, index) => (
                            <CaseDetailItem key={index} body={caseItem} />
                          ))}
                    </CardSubContent>
                  </>
                ) : (
                  <p>Loading case details...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseViewer;
