import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ensureHeaders } from './auth/useSetHeaders';
import { API_URL } from './Constants';

// const Analytics2 = () => {
//   const [analyticsData, setAnalyticsData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchCitationData = async () => {
//         await ensureHeaders();
//         axios.post(API_URL + 'analytics')
//         .then(response => {
//             setAnalyticsData(response.data);
//             setLoading(false);
//         })
//         .catch(err => {
//             console.error('Error fetching analytics data:', err);
//             setError(err);
//             setLoading(false);
//         });
//     };
//     fetchCitationData();
//   }, []);

//   if (loading) return <div>Loading analytics data...</div>;
//   if (error) return <div>Error: {error.message}</div>;

//   return (
//     <div>
//       <h1>50 Most Recent Step Analytics</h1>
//       <table border="1" cellPadding="8" cellSpacing="0">
//         <thead>
//           <tr>
//             <th>ID</th>
//             <th>Date</th>
//             <th>User ID</th>
//             <th>Draft ID</th>
//             <th>Document Type</th>
//             <th>Step</th>
//           </tr>
//         </thead>
//         <tbody>
//           {analyticsData.map(item => (
//             <tr key={item.id}>
//               <td>{item.id}</td>
//               <td>{new Date(item.date).toLocaleString()}</td>
//               <td>{item.userId}</td>
//               <td>{item.draftId}</td>
//               <td>{item.docType}</td>
//               <td>{item.step}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default Analytics2;


const Analytics = () => {
    const [analyticsData, setAnalyticsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const perPage = 50; // Items per page
    const [total, setTotal] = useState(0);
  
    // Function to fetch data from the paginated endpoint.
    const fetchData = async (currentPage) => {
      setLoading(true);
      await ensureHeaders();
      axios.post(`${API_URL}analytics?page=${currentPage}&per_page=${perPage}`)
        .then(response => {
          const data = response.data;
          setAnalyticsData(data.results);
          setTotal(data.total);
          setLoading(false);
        })
        .catch(err => {
          console.error('Error fetching analytics data:', err);
          setError(err);
          setLoading(false);
        });
    };
  
    // Fetch data whenever the page changes.
    useEffect(() => {
      fetchData(page);
    }, [page]);
  
    // Calculate total pages.
    const totalPages = Math.ceil(total / perPage);
  
    // Handlers for pagination controls.
    const handlePrev = () => {
      if (page > 1) setPage(page - 1);
    };
  
    const handleNext = () => {
      if (page < totalPages) setPage(page + 1);
    };
  
    if (loading) return <div>Loading analytics data...</div>;
    if (error) return <div>Error: {error.message}</div>;
  
    return (
      <div>
        <h1>Step Analytics (Page {page} of {totalPages})</h1>
        <table border="1" cellPadding="8" cellSpacing="0">
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>User Email</th>
              <th>Draft ID</th>
              <th>Document Type</th>
              <th>Step</th>
            </tr>
          </thead>
          <tbody>
            {analyticsData.map(item => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{new Date(item.date).toLocaleString()}</td>
                <td>{item.email}</td>
                <td>{item.draftId}</td>
                <td>{item.docType}</td>
                <td>{item.step}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ marginTop: '20px' }}>
          <button onClick={handlePrev} disabled={page === 1}>
            Previous
          </button>
          <span style={{ margin: '0 10px' }}>
            Page {page} of {totalPages}
          </span>
          <button onClick={handleNext} disabled={page === totalPages}>
            Next
          </button>
        </div>
      </div>
    );
  };
  
  export default Analytics;
