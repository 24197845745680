import React, { useState } from 'react';

function Step1({ fas, background, setFas, setBackground, standard, setStandard, onSubmit, onPartialUpdate }) {
  const [additionalFact, setAdditionalFact] = useState('');

  // Called when user clicks "Update" next to additional facts
  const handleUpdateFacts = () => {
    if (!additionalFact.trim()) return;
    // Locally push to the "fas" array if you want to see it in the list:
    setFas([...fas]);

    // CALL THE PARENT partial update to re-run the LLM:
    onPartialUpdate(additionalFact);

  };

  return (
    <div>
      <div className="block">
        <h1 className="title is-4" style={{ marginBottom: '8px' }}>
          Summary
        </h1>
      </div>

      <form onSubmit={onSubmit}>

        {/* Background Section */}
        <div className="card box is-draft" style={{ padding: '16px', marginTop: '16px' }}>
          <h3 className="title is-7" style={{ marginBottom: '8px' }}>
            Background
          </h3>
          <textarea
            name="background"
            className="textarea"
            value={background}
            onChange={(e) => setBackground(e.target.value)}
            rows={12}
            style={{ width: '100%', maxWidth: '540px' }}
          />
        </div>

        <div className="card box is-draft" style={{ padding: '16px' }}>
          {/* <h3 className="title is-7" style={{ marginBottom: '8px' }}>
            Factual Allegations
          </h3>
          <ul style={{ listStyleType: 'disc', paddingLeft: '20px', maxWidth: '540px' }}>
            {fas.map((item, index) => (
              <li key={index} className="body list">
                {item}
              </li>
            ))}
          </ul> */}
          <div style={{ marginTop: '16px' }}>
            <textarea
            name = "additionalFact"
              className="textarea"
              placeholder="Add additional facts here..."
              value={additionalFact}
              onChange={(e) => setAdditionalFact(e.target.value)}
              rows={4}
              style={{ width: '100%', maxWidth: '540px' }}
            />
            <button 
              className="button is-info" 
              onClick={handleUpdateFacts}
              style={{ marginTop: '8px' }}
              type="button"
            >
              Update Background
            </button>
          </div>
        </div>

        <div className="card box is-draft" style={{ padding: '16px', marginTop: '16px' }}>
            <h3 className="title is-7" style={{ marginBottom: '8px' }}>
                Legal Standard Section
            </h3>
            <textarea
                name="standard"
                className="textarea"
                value={standard}
                onChange={(e) => setStandard(e.target.value)}
                rows={12}
                style={{ width: '100%', maxWidth: '540px' }}
            />
            </div>

        <div className="has-text-right" style={{ marginTop: '16px' }}>
          <button className="button is-normal is-success" type="submit">
            Save and Continue
          </button>
        </div>
      </form>
    </div>
  );
}

export default Step1;
