// ResponseDrafter.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL, ERROR_MSG_500 } from '../Constants.js';
import Step0 from './Step0.js';
import Step1 from './Step1.js';
import Step2 from './Step2.js';
import Step3 from './Step3.js'; 
import ModalWithProgressBar from '../ModalWithProgressBar.js';
import { ensureHeaders } from '../auth/useSetHeaders';
import Icon from '@mdi/react';
import { mdiFileDocumentEditOutline, mdiTrayArrowUp, mdiTrayArrowDown, mdiCloudUploadOutline, mdiFileEditOutline, mdiProgressDownload, mdiNewBox } from '@mdi/js';
import '../global.css';

function RfpDrafter() {
  const [fileName, setFileName] = useState(process.env.PUBLIC_URL + '/CV-126.pdf');
  const [key, setKey] = useState(0);
  const [appState, setAppState] = useState(0); // Initial state
  const [taskId, setTaskId] = useState(null);  // For async
  const [selectedFile, setSelectedFile] = useState(null);
  const [definition, setDefinition] = useState("A proposed definition section will appear here after RFPs have been selected.");
  const [coas, setCoas] = useState([["(Breach of Contract)", "An explanation why this is a good cause will appear here"], 
  ["(California Penal Code 502)", "An explanation why this is a good cause will appear here"]]);
  const [instructions, setInstructions] = useState(["Proposed instructions will appear here."]);
  const [intro, setIntro] = useState("A proposed introduction will appear here after RFPs have been selected.");

  // What happens next section data
  const nextStepsData = [
    {
      id: 1,
      icon: mdiCloudUploadOutline,
      description:
        <>
          <strong>Upload Complaint</strong> (PDF or DOC; remove attachments)
        </>
    },
    {
      id: 2,
      icon: mdiFileEditOutline,
      description:
        <>
          <strong>Draft your document step-by-step</strong> (input/edit on left, preview updates next page on right)
        </>
    },
    {
      id: 3,
      icon: mdiProgressDownload,
      description:
        <>
          <strong>Download your draft Word doc</strong>
        </>
    },
    {
      id: 4,
      icon: mdiNewBox,
      description:
        <>
          <strong>Quick tip:</strong> Legal research is always available at the top—just ask in plain English.
        </>,
      isSeparate: true,
    },
  ];

  // modal 
  const [modalIsActive, setModaIsActive] = useState(false);
  const [modalProgress, setModalProgress] = useState(0);

  let { draftId } = useParams();

  const advSt = () => {
    setAppState(appState + 1);
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    if (selectedFile) {
      formData.append('file', selectedFile);
      console.log(formData);
    }
    if (!selectedFile && appState === 0){
      alert("Please select a file before submitting!");
      return
    }

    // formData.appState = appState;
    formData.append('appState', appState)
    formData.append('draftId', parseInt(draftId))

    try {
      await ensureHeaders()
      const response = await axios.post(API_URL + 'rfpdrafter', formData);
      // Axios automatically handles the conversion of the response to JSON
      const result = response.data;
      if (response.status === 200) {
        setTaskId(result.result_id);
        console.log('Task submitted successfully');
        setModaIsActive(true); // Activate the modal only after a successful response
        setModalProgress(5);
      } else {
        console.error('Form submission failed');
      }
    } catch (error) {
      if (error.response){
        // The request was made and the server responded with a status code
        alert(error.response.data.message); // Display the error message from the backend
      } else{
        console.error('Form submission failed', error);
      }
    }
    };

  // Function to check the task status
  const checkTaskStatus = async () => {
    try {
      console.log("checking the status");
      const response = await axios.get(`${API_URL}result/${taskId}`);
      if (response.data.ready) {
        setTaskId(null); // Reset taskId
        console.log("the data is ready")
        console.log(response.data);

        // this could be streamlined with a map
        if ('instructions' in response.data.value){
          setInstructions(response.data.value.instructions);
        }
        if ('coa_suggestions' in response.data.value){
          setCoas(response.data.value.coa_suggestions);
        }
        if ('definition' in response.data.value){
          setDefinition(response.data.value.definition);
        }
        if ('intro' in response.data.value){
          setIntro(response.data.value.intro);
        }

        setModalProgress(100);
        // update the file to display
        setFileName(response.data.value.file_name);
        setKey(prevKey => prevKey + 1);
        advSt();

        setModaIsActive(false);
        
      }
    } catch (error) {
      console.error('Error checking task status:', error);
      setTaskId(null);
      setModaIsActive(false);
      alert(ERROR_MSG_500);
    }
  };
    
  // Start polling when taskId is set
  useEffect(() => {
    let intervalId;
    if (taskId) {
      intervalId = setInterval(() => {
        checkTaskStatus();
        let delta = appState === 0 ? 7 : 5;
        setModalProgress(modalProgress => modalProgress + delta);
      }, 5000); // Poll every 5000 milliseconds (5 seconds)
    }
    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [taskId]);


    // Array to hold tab data
    const tabs = [
        { icon: mdiTrayArrowUp, text: 'Upload' },
        { icon: mdiFileDocumentEditOutline, text: 'RFPs' },
        { icon: mdiFileDocumentEditOutline, text: 'Supporting Sections' },
        { icon: mdiTrayArrowDown, text: 'Finish' }
    ];

  return (
    <div className="App">
        <div className="split left">
            <div className="dropdown-container" style={{margin: '20px 32px'}}>
            <div className="tabs is-toggle is-progress">
                <ul>
                    {tabs.map((tab, index) => (
                        <li key={index} className={index === appState ? 'is-active' : ''}>
                        <a onClick={() => setAppState(index)} style={{ padding: '8px', display: 'inline-flex', alignItems:'center' }}>
                            <span className="icon is-small">
                            <Icon path={tab.icon} size={1} />
                            </span>
                            <span>{tab.text}</span>
                        </a>
                        </li>
                    ))}
                </ul>
            </div>
                <div>
                    {appState === 0 && <Step0 onSubmit={handleSubmit} onChange={handleFileChange} fileName={selectedFile}/>}
                    {appState === 1 && <Step1 onSubmit={handleSubmit} advSt={advSt} initialCoas={coas}/>}
                    {appState === 2 && <Step2 onSubmit={handleSubmit} advSt={advSt} intro={intro} defs={definition} instructions={instructions}/>}
                    {appState === 3 && <Step3 draftId={draftId} />}
                </div>
            </div> 
            <ModalWithProgressBar isActive={modalIsActive} progress={modalProgress} />
        </div>
        <div className="split right">
          {appState === 0 ? (
            <>
              {/* Instructions */}
              <div style={{ marginTop: "20px", marginRight: "20px", paddingBottom: "24px", display: 'flex', height: "calc(100% - 20px)" }}>
                <div style={{ background: "#F8FAFC", border: '1px solid #D1D1D6', borderRadius: '24px', flex: 1, padding: '0px 32px' }}>
                  <div className='hide-scrollbar' style={{ maxWidth: "402px", margin: "0 auto", paddingTop: "110px", height: "100%", overflowY: "auto", paddingBottom: '40px',  }}>
                    <div style={{ paddingBottom: '40px', textAlign: 'center' }}>
                      <h2 className='title is-4' style={{ color: "var(--color-primary)" }}>What Happens Next</h2>
                    </div>

                    {/* What happens next bullets */}
                    <div style={{ padding: '0px 12px 28px 12px', borderBottom: '1px solid #C7C7CC', display: 'flex', flexDirection: 'column', gap: '24px' }}>
                      {nextStepsData
                        .filter((step) => !step.isSeparate)
                        .map((step) => (
                          <div key={step.id} style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                            <span style={{ height: '32px' }}>
                              <Icon path={step.icon} size="32px" title={step.title} color={ "var(--color-primary)" }/>
                            </span>
                            <p> {step.description} </p>
                          </div>
                        ))}
                    </div>

                    {/*Legal research now available*/}
                    <div style={{ padding: '28px 12px 0px 12px' }}>
                      {nextStepsData
                        .filter((step) => step.isSeparate)
                        .map((step) => (
                          <div key={step.id} style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                            <span style={{ height: '32px' }}>
                              <Icon path={step.icon} size="32px" title={step.title} color={ "var(--color-primary)" }/>
                            </span>
                            <p> {step.description}</p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ):(
            <>
              {/* pdf viewer */}
              <embed key={key} src={fileName} width={"100%"} height={2000} />
            </>
          )}
        </div>
    </div>
  );
}

export default RfpDrafter;
