// Step1.js

import React, { useState } from 'react';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import '../global.css';


function Step1({onSubmit, instObjections, defObjections}) {
  const [textAreas, setTextAreas] = useState(defObjections);
  const [textAreasI, setTextAreasI] = useState(instObjections);
 
  function addTextArea() {
    setTextAreas([...textAreas, '']); // Add a new empty textarea
  }

  function addTextAreaI() {
    setTextAreasI([...textAreas, '']); // Add a new empty textarea
  }

  return (
    <div>
      <div className='block'><h3 className="title is-4" style={{ marginBottom: '8px' }}>Definitions Objections and Responses</h3></div>
      <form onSubmit={onSubmit}>
        <div className='block'>
          {Object.entries(defObjections).map(([key, item]) => (
            <div key={item.id} className="box is-draft">
              <h3 className="title is-5">Definition No. {item.id}</h3>
              <p>{item.definition}</p> {/* Display the definition */}
              <h3 className="title is-6" style={{ marginTop: '16px' }}>Objection</h3> {/* Header for the Objection */}
              <textarea 
              className="textarea" 
              id={`def${key}`} 
              name={`def${key}`} 
              defaultValue={item.objection} 
              rows="4" 
              style={{ marginBottom: '24px', color: item.objection ? 'black' : 'gray' }} 
              placeholder={item.objection ? '' : 'Add Objection'} /> {/* Placeholder text */}

              {/* Only show the checkbox if item.objection is not an empty string */}
              {item.objection && (
                <div className="field">
                  <label htmlFor={`ignore-def${item.id}`}>
                    <input className="is-checkradio" type="checkbox" id={`ignore-def${item.id}`} name={`ignore-def${item.id}`} />
                    Ignore this Objection
                  </label>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className='block'><h3 className="title is-4" style={{ marginTop:'80px', marginBottom: '8px' }}>Instructions Objections and Responses</h3></div>
        <div className='block'>
          {Object.entries(instObjections).map(([key, item]) => (
            <div key={item.id} className="box is-draft">
              <h3 className="title is-5">Instruction No. {item.id}</h3>
              <p>{item.instruction}</p> {/* Display the definition */}
              <h3 className="title is-6" style={{ marginTop: '16px' }}>Objection</h3> {/* Header for the Objection */}
              <textarea 
              className="textarea" 
              id={`inst${key}`} 
              name={`inst${key}`} 
              defaultValue={item.objection} 
              rows="4" 
              style={{ marginBottom: '24px', color: item.objection ? 'black' : 'gray' }} 
              placeholder={item.objection ? '' : 'Add Objection'} /> {/* Placeholder text */}

              {/* Only show the checkbox if item.objection is not an empty string */}
              {item.objection && (
                <div className="field">
                  <label htmlFor={`ignore-inst${item.id}`}>
                    <input className="is-checkradio" type="checkbox" id={`ignore-inst${item.id}`} name={`ignore-inst${item.id}`} />
                    Ignore this Objection
                  </label>
                </div>
              )}
            </div>
          ))}
        </div>
        
        <div className='block has-text-right'>
          <button className="button is-normal is-success" type="submit">Update Draft</button>
        </div>
      </form>
    </div>
  );
}

export default Step1;