// ElementChecker.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL, ERROR_MSG_500 } from '../Constants.js';
import Step0 from './Step0.js';
import Step1 from './Step1.js';
import Step2 from './Step2.js';
import Step3 from './Step3.js';
// import Step4 from '../rfp/Step3.js';
import Step4 from './Step4.js';

import ModalWithProgressBar from '../ModalWithProgressBar.js';
import { ensureHeaders } from '../auth/useSetHeaders';
import Icon from '@mdi/react';
import { mdiFileDocumentEditOutline, mdiTrayArrowUp, mdiTrayArrowDown, mdiCloudUploadOutline, mdiFileEditOutline, mdiProgressDownload, mdiNewBox, mdiMagnifyScan, mdiTextBoxCheckOutline } from '@mdi/js';
import '../global.css';


function ElementChecker() {
  const [fileName, setFileName] = useState(process.env.PUBLIC_URL + '/CV-126.pdf');
  const [key, setKey] = useState(0);
  const [appState, setAppState] = useState(0); // Initial state
  const [taskId, setTaskId] = useState(null);  // For async
  const [selectedFile, setSelectedFile] = useState(null);
  const [elData, setElData] = useState([
  ["Contributory Copyright Infringement", {
      description: "This cause of action is even worse.",
      elements: [
        {'name': 'Copying of constitutive elements of the work that are original', 
          'source': ['Feist Publications, Inc. v. Rural Tel. Serv. Co., 499 U.S. 340, 361 (1991) (to establish infringement, one must prove unauthorized copying of original elements)', '17 U.S.C. § 501(a) (describes actions for infringement)'], 
          'source_info': [{'prefix': '/CaseViewer', 'citationName': '499-U<dot>S<dot>-340', 'id': 112568, 'displayName': 'Feist Publications, Inc. v. Rural Telephone Service Co.\t499 U.S. 340', 'type': 'case'}, 
            {'prefix': 'https://www.law.cornell.edu/uscode/text', 'id': null, 'displayName': '17 U.S.C. § 501', 'citationName': '17/501', 'type': 'us'}
          ], 
            'weakness_cit_info': [], 
            'satisfied': true, 
            'analysis': ''}, 
        {'name': 'Volitional conduct on the part of the defendant leading to unauthorized reproduction, distribution, display, or public performance of the protected work', 
          'source': ['Perfect 10, Inc. v. Giganews, Inc., 847 F.3d 657, 666 (9th Cir. 2017) (requires volitional conduct to establish direct copyright infringement)'], 
          'source_info': [{'prefix': '/CaseViewer', 'citationName': '847-F<dot>3d-657', 'id': 4340456, 'displayName': 'Perfect 10, Inc. v. Giganews, Inc.\t847 F.3d 657', 'type': 'case'}], 
          'satisfied': false, 
          'analysis': "Weakness: The Complaint does not clearly state how Gawker Media's actions amount to direct infringing conduct as they merely provided links to a third-party site (Compl. ¶19), leaving doubt if the conduct meets the threshold of volitional acts leading to infringement.\nFocus of Challenge: Argue that Gawker Media's action of merely linking does not meet the standard for volitional conduct needed for direct liability in a copyright infringement claim, as mere facilitation of access, without more, may not suffice (see Perfect 10, Inc. v. Amazon.com, Inc., 508 F.3d 1146 (9th Cir. 2007)).",
        'weakness_cit_info': [{'prefix': '/CaseViewer', 'citationName': '508-F<dot>3d-1146', 'id': 1228127, 'displayName': 'Perfect 10, Inc. v. Amazon. Com, Inc.\t508 F.3d 1146', 'type': 'case'}]
      }
      ]
    }
  ]
  ]);
  const [recomendations, setRecomendations] = useState([
    [
      "Direct Copyright Infringement [17 U.S.C. § 101 et. seq.]",
      [
        {
          recommendation: 'Here are some recomendations: first you should hire a lawyer.',
          citations: [
            { 
              type: 'state',
              prefix: 'https://example.com/state-law', 
              citationName: 'Section101',
              id: '101',
              displayName: '17 U.S.C. § 101'
            },
            { 
              type: 'state',
              prefix: 'https://example.com/state-law', 
              citationName: 'Section102',
              id: '102',
              displayName: '17 U.S.C. § 102'
            }
          ],
          citations_raw: ['ABC', 'EDF'],
          citations_content: [
            'The citation link is embedded across the entire displayed text. Therefore, if the displayed text includes additional content beyond the Bluebook format, that extra content will also be part of the hyperlink.',
            '17 U.S.C. § 102'
          ]
        },
        {
          recommendation: 'Here are some recomendations: second pay the fees.',
          citations: [
            { 
              type: 'state',
              prefix: 'https://example.com/state-law', 
              citationName: 'Section102',
              id: '102',
              displayName: '17 U.S.C. § 102'
            }
          ],
          citations_raw: ['ABC', 'EDF'],
          citations_content: ['Citation content for 17 U.S.C. § 102']
        }
      ]
    ],
    [
      "Contributory Copyright Infringement",
      [
        {
          recommendation: 'Here are some recomendations: first you should hire a lawyer.',
          citations: [
            { 
              type: 'state',
              prefix: 'https://example.com/state-law', 
              citationName: 'Section201',
              id: '201',
              displayName: '17 U.S.C. § 201'
            }
          ],
          citations_raw: ['ABC', 'EDF'],
          citations_content: ['Citation content for contributory infringement 201']
        },
        {
          recommendation: 'Here are some recomendations: second pay the fees.',
          citations: [
            { 
              type: 'state',
              prefix: 'https://example.com/state-law', 
              citationName: 'Section202',
              id: '202',
              displayName: '17 U.S.C. § 202'
            }
          ],
          citations_raw: ['ABC', 'EDF'],
          citations_content: ['Citation content for contributory infringement 202']
        }
      ]
    ]
  ]);
  const [coas, setCoas] = useState(['First COA', 'Second COA']);
  const [fas, setFas] = useState(['First FA', 'Second FA', 'Third FA']);

  // What happens next section data
  const nextStepsData = [
    {
      id: 1,
      icon: mdiCloudUploadOutline,
      description:
        <>
          <strong>Upload Complaint and choose side</strong> (Plaintiff or Defendant)
        </>
    },
    {
      id: 2,
      icon: mdiFileEditOutline,
      description:
        <>
          <strong>Review instant summary</strong> of facts and claims
        </>
    },
    {
      id: 3,
      icon: mdiMagnifyScan,
      description:
        <>
          <strong>Analyze claims:</strong> See which elements are supported or fall short (with case law/statutes)
        </>
    },
    {
      id: 4,
      icon: mdiTextBoxCheckOutline,
      description:
        <>
          <strong>Get targeted recommendations</strong> (Amend Complaint or Motion to Dismiss)
        </>
    },
    {
      id: 5,
      icon: mdiProgressDownload,
      description:
        <>
          <strong>Download summary and recommendations</strong>
        </>
    },
    {
      id: 6,
      icon: mdiNewBox,
      description:
        <>
          <strong>Quick tip:</strong> Legal research is always available at the top—just ask in plain English.
        </>,
      isSeparate: true,
    },
  ];

  // modal 
  const [modalIsActive, setModaIsActive] = useState(false);
  const [modalProgress, setModalProgress] = useState(0);

  let { draftId } = useParams();

  const advSt = () => {
    setAppState(appState + 1);
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    if (selectedFile) {
      formData.append('file', selectedFile);
      console.log(formData);
    }
    if (!selectedFile && appState === 0){
      alert("Please select a file before submitting!");
      return
    }

    // formData.appState = appState;
    formData.append('appState', appState)
    formData.append('draftId', parseInt(draftId))

    try {
      await ensureHeaders()
      const response = await axios.post(API_URL + 'elementchecker', formData);
      // Axios automatically handles the conversion of the response to JSON
      const result = response.data;
      if (response.status === 200) {
        setTaskId(result.result_id);
        console.log('Task submitted successfully');
        setModaIsActive(true); // Activate the modal only after a successful response
        setModalProgress(5);
      } else {
        console.error('Form submission failed');
      }
    } catch (error) {
      if (error.response){
        // The request was made and the server responded with a status code
        alert(error.response.data.message); // Display the error message from the backend
      } else{
        console.error('Form submission failed', error);
      }
    }
    };

  // Function to check the task status
  const checkTaskStatus = async () => {
    try {
      console.log("checking the status");
      const response = await axios.get(`${API_URL}result/${taskId}`);
      if (response.data.ready) {
        setTaskId(null); // Reset taskId
        console.log("the data is ready")
        console.log(response.data);

        setModalProgress(100);
        // update the file to display
        if ('file_name' in response.data.value) {
          setFileName(response.data.value.file_name);
          setKey(prevKey => prevKey + 1);
        }

        if ('el_data' in response.data.value){
          console.log("setting el data");

          setElData(response.data.value.el_data);
          console.log(response.data.value.el_data);
        }
        if ('recomendations' in response.data.value) {
          setRecomendations(response.data.value.recomendations);
        }
        if ('coas' in response.data.value) {
          setCoas(response.data.value.coas);
        }
        if ('fas' in response.data.value) {
          setFas(response.data.value.fas);
        }
        advSt();

        setModaIsActive(false);
        
      }
    } catch (error) {
      console.error('Error checking task status:', error);
      setTaskId(null);
      setModaIsActive(false);
      alert(ERROR_MSG_500);
    }
  };
    
  // Start polling when taskId is set
  useEffect(() => {
    let intervalId;
    if (taskId) {
      intervalId = setInterval(() => {
        checkTaskStatus();
        let delta = appState === 0 ? 7 : 5;
        setModalProgress(modalProgress => modalProgress + delta);
      }, 5000); // Poll every 5000 milliseconds (5 seconds)
    }
    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [taskId]);


    // Array to hold tab data
    const tabs = [
        { icon: mdiTrayArrowUp, text: 'Upload' },
        { icon: mdiFileDocumentEditOutline, text: 'Summary' },
        { icon: mdiFileDocumentEditOutline, text: 'Analysis' },
        { icon: mdiFileDocumentEditOutline, text: 'Recommendations' },
        { icon: mdiTrayArrowDown, text: 'Download' }
    ];

  return (
    <div className="App">
        <div className="split left">
            <div className="dropdown-container" style={{ margin: '20px 32px 20px 20px' }}>
            <div className="tabs is-toggle is-progress">
                <ul>
                    {tabs.map((tab, index) => (
                        <li key={index} className={index === appState ? 'is-active' : ''}>
                        <a  onClick={() => setAppState(index)} style={{ padding: '8px', display: 'inline-flex', alignItems:'center' }}>
                            <span className="icon is-small">
                              <Icon path={tab.icon} size={1} />
                            </span>
                            <span>{tab.text}</span>
                        </a>
                        </li>
                    ))}
                </ul>
            </div>
                <div>
                    {appState === 0 && <Step0 onSubmit={handleSubmit} onChange={handleFileChange} fileName={selectedFile}/>}
                    {appState === 1 && <Step1 onSubmit={handleSubmit} coas={coas} fas={fas}/>}
                    {appState === 2 && <Step2 onSubmit={handleSubmit} inputObj={elData} />}
                    {appState === 3 && <Step3 onSubmit={handleSubmit} recomendations={recomendations} />}
                    {appState === 4 && <Step4 draftId={draftId} />}
                </div>
            </div> 
            <ModalWithProgressBar isActive={modalIsActive} progress={modalProgress} />
        </div>
        <div className="split right">
          {appState === 0 ? (
            <>
              {/* Instructions */}
              <div style={{ marginTop: "20px", marginRight: "20px", paddingBottom: "24px", display: 'flex', height: "calc(100% - 20px)" }}>
                <div style={{ background: "#F8FAFC", border: '1px solid #D1D1D6', borderRadius: '24px', flex: 1, padding: '0px 32px' }}>
                  <div className='hide-scrollbar' style={{ maxWidth: "402px", margin: "0 auto", paddingTop: "110px", height: "100%", overflowY: "auto", paddingBottom: '40px',  }}>
                    <div style={{ paddingBottom: '40px', textAlign: 'center' }}>
                      <h2 className='title is-4' style={{ color: "var(--color-primary)" }}>What Happens Next</h2>
                    </div>

                    {/* What happens next bullets */}
                    <div style={{ padding: '0px 12px 28px 12px', borderBottom: '1px solid #C7C7CC', display: 'flex', flexDirection: 'column', gap: '24px' }}>
                      {nextStepsData
                        .filter((step) => !step.isSeparate)
                        .map((step) => (
                          <div key={step.id} style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                            <span style={{ height: '32px' }}>
                              <Icon path={step.icon} size="32px" title={step.title} color={ "var(--color-primary)" }/>
                            </span>
                            <p> {step.description} </p>
                          </div>
                        ))}
                    </div>

                    {/*Legal research now available*/}
                    <div style={{ padding: '28px 12px 0px 12px' }}>
                      {nextStepsData
                        .filter((step) => step.isSeparate)
                        .map((step) => (
                          <div key={step.id} style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                            <span style={{ height: '32px' }}>
                              <Icon path={step.icon} size="32px" title={step.title} color={ "var(--color-primary)" }/>
                            </span>
                            <p> {step.description}</p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ):(
            <>
              {/* pdf viewer */}
              <embed key={key} src={fileName} width={"100%"} height={2000} />
            </>
          )}
        </div>
    </div>
  );
}

export default ElementChecker;
