// Step3.js

import React, { useState } from 'react';
import '../global.css';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp, mdiOpenInNew } from '@mdi/js';


function Step3({onSubmit, recomendations}) {
  
  // State to track visibility of each card content
    const [visibleCards, setVisibleCards] = useState(
      Object.fromEntries(recomendations.map(([causeOfAction]) => [causeOfAction, false]))
    );
  
    // Toggle visibility function
    const toggleVisibility = (causeOfAction) => {
      setVisibleCards((prevVisibleCards) => ({
        ...prevVisibleCards,
        [causeOfAction]: !prevVisibleCards[causeOfAction] // Toggle the visibility
      }));
    };

  return (
    <div>      
      <div className="block">
        <h1 className="title is-4" style={{ marginBottom: '8px' }}>Recommendations</h1>
      </div>

      <form onSubmit={onSubmit}>
        {/* <div className='block'>
        <textarea className="textarea" name="recomendations" id="recomendations" defaultValue={recomendations.body} rows="20" cols="60"></textarea>
        </div> */}

        <div>
          {recomendations.map(([causeOfAction, recArray]) => (
            <div key={causeOfAction} className='card box is-draft' style={{ padding: '0' }}>
              <header className={`card-header ${visibleCards[causeOfAction] ? 'is-expanded' : ''}`} style={{ boxShadow: 'none' }}>
                <h2 className="subtitle title is-7" style={{ margin: '0px 0px 0px 16px', flex: 1 }}>{causeOfAction}</h2>
                <button type="button" className="card-header-icon" aria-label="toggle visibility" onClick={() => toggleVisibility(causeOfAction)}>
                  <Icon path={visibleCards[causeOfAction] ? mdiChevronUp : mdiChevronDown} size={1} />
                </button>
              </header>
              {visibleCards[causeOfAction] && (
                <div className="card-content" style={{ padding: '20px 24px 12px 24px' }}>
                  <ul>
                    {recArray.map((recObj, index) => (
                      <li key={index} className='citation-link'>
                        <div><strong>Recommendation {index + 1}</strong></div>
                        {recObj.recommendation}
                        <div style={{ paddingTop: '12px'}}><strong>Citations:</strong></div>
                        {recObj.citations_content && recObj.citations_content.length > 0 && (
                          recObj.citations_content.map((citation_text, cIndex) => {
                            const citationData = recObj.citations && recObj.citations[cIndex];
                            return (
                              <div key={cIndex} className="citation" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '4px' }}>
                                <div style={{ width: '1em', flexShrink: 0, marginLeft: '0.5em' }}>{'•'}</div>
                                <span style={{ flex: 1, display: 'block' }}>
                                  {citationData ? (
                                    <a
                                      href={
                                        citationData.type === 'state'
                                          ? citationData.prefix
                                          : `${citationData.prefix}/${citationData.citationName}${citationData.id ? `&${citationData.id}` : ''}`
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {citation_text}
                                      <Icon 
                                        path={mdiOpenInNew}
                                        size={0.7}
                                        style={{ marginLeft: '6px', verticalAlign: 'middle' }}
                                        aria-label="Open in new window"
                                      />
                                    </a>
                                  ) : (
                                    citation_text
                                  )}
                                </span>
                              </div>
                            );
                          })
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="has-text-right" style={{ marginTop: "24px" }}>
          <button className="button is-normal is-success" type="submit">Proceed to download</button>
        </div>
      </form>
    </div>
  );
}

export default Step3;
